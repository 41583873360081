import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { GetProfileRequest, UpdateProfileRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import src from '../../../asset/face.png';
import moment from "moment";

const ViewProfile = () => {

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var editdata = fetchacompanyReducer?.GetProfileResponse

    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [email, setemail] = useState("")
    const [mobile, setmobile] = useState("")
    const [companyname, setcompanyname] = useState("")
    const [createddate, setcreateddate] = useState("")
    const [title, settitle] = useState("")
    const [addressone, setaddressone] = useState("")
    const [addresstwo, setaddresstwo] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [zipcode, setzipcode] = useState("")
    const [country, setcountry] = useState("")
    const [status, setstatus] = useState("")
    const [profileimg, setprofileimg] = useState(null);
    const [src, setSrc] = useState('');

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(GetProfileRequest(obj))
    }, [])

    const handleImageChange = (e) => {
        setprofileimg(e.target.files[0]);
        setSrc(URL.createObjectURL(e.target.files[0])); // Preview the selected image
    };

    useEffect(() => {
        var editdata = fetchacompanyReducer?.GetProfileResponse
        console.log(editdata)
        setfname(editdata?.first_name)
        setlname(editdata?.last_name)
        setemail(editdata?.email)
        setmobile(editdata?.phone)
        const formattedDate = moment(editdata?.created_at).format('YYYY-MM-DD');
        setcreateddate(formattedDate);
        setcompanyname(editdata?.company_name)
        settitle(editdata?.title_of_person_in_charge)
        setprofileimg(editdata?.company_logo_url)

    }, [fetchacompanyReducer?.GetProfileResponse])

    const SubmitHandle = () => {
        if (src == '') {
            Swal.fire({
                icon: "error",
                text: 'Image should be must',
            });
        } else {

        // let obj = {
        //     "first_name": fname,
        //     "last_name": lname,
        //     "email": email,
        //     "phone": mobile,
        //     "profileimg": profileimg

        // }

        const formData = new FormData();
        // formData.append('first_name', fname);
        if (profileimg) {
            formData.append('profileimg', profileimg);
        }

        dispatch(UpdateProfileRequest({ formData: formData, id: id, navigate: navigate }))

        }
    }

    return (

        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Profile Details</h3>
                </div>
                <div className='row form-style' style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Company Name</label>
                            <input type='text' disabled className='form-control' placeholder='Company Name'
                                value={companyname}
                                onChange={(e) => setcompanyname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Created Date</label>
                            <input type='text' disabled className='form-control' placeholder='Created Date'
                                value={createddate}
                                onChange={(e) => setcreateddate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>First Name</label>
                            <input type='text' disabled className='form-control' placeholder='First Name'
                                value={fname}
                                onChange={(e) => setfname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Last Name</label>
                            <input type='text' disabled className='form-control' placeholder='Last Name'
                                value={lname}
                                onChange={(e) => setlname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className='form-group'>
          <div className='profileimg' onClick={() => document.getElementById('fileInput').click()} style={{ cursor: 'pointer', position: 'relative' }}>
            <img className='imgsize' src={src} style={{ maxWidth: '100%', height: 'auto' }} />
            <div className='hover-text'><i class="fas fa-user-edit"></i></div>
          </div>
          <input type='file' id='fileInput' onChange={handleImageChange} style={{ display: 'none' }} />
          <button style={{ width: '100%', marginTop: '10px' }} onClick={SubmitHandle}> Update Image </button>
        </div>
      </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Email</label>
                            <input type='text' disabled className='form-control' placeholder='Email'
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Mobile Number</label>
                            <input type='text' disabled className='form-control' placeholder='Mobile No.'
                                value={mobile}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Title of Person In-Charge</label>
                            <input type='text' disabled className='form-control' placeholder='Person In-Charge'
                                value={title}
                                onChange={(e) => settitle(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Status</label>
                            <input type='text' disabled className='form-control' placeholder='Status'
                                value={status}
                                onChange={(e) => setemail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Address 1</label>
                            <input type='text' disabled className='form-control' placeholder='Address 1'
                                value={addressone}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Address 2</label>
                            <input type='text' disabled className='form-control' placeholder='Address 2'
                                value={addresstwo}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Country</label>
                            <input type='text' disabled className='form-control' placeholder='Country'
                                value={country}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>State</label>
                            <input type='text' disabled className='form-control' placeholder='State'
                                value={state}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>City</label>
                            <input type='text' disabled className='form-control' placeholder='City'
                                value={city}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='form-group'>
                            <label className='selectlabel'>Zipcode</label>
                            <input type='text' disabled className='form-control' placeholder='Zipcode'
                                value={zipcode}
                                onChange={(e) => setmobile(e.target.value)}
                            />
                        </div>
                    </div>

                </div>
                {/* <button disabled className='formBtn' onClick={SubmitHandle}>Update</button> */}
            </div>

        </div>
    )
}

export default ViewProfile