import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink } from 'react-router-dom'
import { AddlocationRequest, GetCountryRequest, GetstateSuccess, GetcitySuccess, GetcityRequest, GetStateRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from 'axios'

const AddLocation = () => {
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    const dispatch = useDispatch()
    let navigate = useNavigate()
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var countryList = fetchacompanyReducer?.GetcountryResponse


    const [location, setlocation] = useState("")
    const [title, settitle] = useState("")
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")
    const [date, setdate] = useState(null)
    const [status, setstatus] = useState("")
    const [url, seturl] = useState("")
    const [countryId, setcountryId] = useState("")
    const [rejected, setrejected] = useState(true)
    const [addressone, setaddressone] = useState("")
    const [addresstwo, setaddresstwo] = useState("")
    const [country, setcountry] = useState("")
    const [stateList, setstateList] = useState([])
    const [mobile, setmobile] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [cityList, setcityList] = useState([])
    const [zipcode, setzipcode] = useState("")
    const [iserror, setiserror] = useState("");
    const [countryCode, setcountryCode] = useState("+1")
    const [countryCodes, setcountryCodes] = useState([])

    
    useEffect(() => {
      dispatch(GetCountryRequest())
      dispatch(GetstateSuccess())
      dispatch(GetcitySuccess())
  }, [])

  useEffect(() => {
    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countryIdMap = {
                "Afghanistan": 1,
                "Aland Islands": 2,
                "Albania": 3,
                "Algeria": 4,
                "American Samoa": 5,
                "Andorra": 6,
                "Angola": 7,
                "Anguilla": 8,
                "Antarctica": 9,
                "Antigua And Barbuda": 10,
                "Argentina": 11,
                "Armenia": 12,
                "Aruba": 13,
                "Australia": 14,
                "Austria": 15,
                "Azerbaijan": 16,
                "Bahrain": 17,
                "Bangladesh": 18,
                "Barbados": 19,
                "Belarus": 20,
                "Belgium": 21,
                "Belize": 22,
                "Benin": 23,
                "Bermuda": 24,
                "Bhutan": 25,
                "Bolivia": 26,
                "Bonaire, Sint Eustatius and Saba": 27,
                "Bosnia and Herzegovina": 28,
                "Botswana": 29,
                "Bouvet Island": 30,
                "Brazil": 31,
                "British Indian Ocean Territory": 32,
                "Brunei": 33,
                "Bulgaria": 34,
                "Burkina Faso": 35,
                "Burundi": 36,
                "Cambodia": 37,
                "Cameroon": 38,
                "Canada": 39,
                "Cape Verde": 40,
                "Cayman Islands": 41,
                "Central African Republic": 42,
                "Chad": 43,
                "Chile": 44,
                "China": 45,
                "Christmas Island": 46,
                "Cocos (Keeling) Islands": 47,
                "Colombia": 48,
                "Comoros": 49,
                "Congo": 50,
                "Cook Islands": 51,
                "Costa Rica": 52,
                "Cote D'Ivoire (Ivory Coast)": 53,
                "Croatia": 54,
                "Cuba": 55,
                "Curaçao": 56,
                "Cyprus": 57,
                "Czech Republic": 58,
                "Democratic Republic of the Congo": 59,
                "Denmark": 60,
                "Djibouti": 61,
                "Dominica": 62,
                "Dominican Republic": 63,
                "East Timor": 64,
                "Ecuador": 65,
                "Egypt": 66,
                "El Salvador": 67,
                "Equatorial Guinea": 68,
                "Eritrea": 69,
                "Estonia": 70,
                "Falkland Islands": 71,
                "Hondura": 72,
                "Faroe Islands": 73,
                "Fiji Islands": 74,
                "Finland": 75,
                "France": 76,
                "French Guiana": 77,
                "French Polynesia": 78,
                "French Southern Territories": 79,
                "Gabon": 80,
                "Gambia The": 81,
                "Georgia": 82,
                "Germany": 83,
                "Ghana": 84,
                "Gibraltar": 85,
                "Greece": 86,
                "Greenland": 87,
                "Grenada": 88,
                "Guadeloupe": 89,
                "Guam": 90,
                "Guatemala": 91,
                "Guernsey and Alderneyia": 92,
                "Guinea": 93,
                "Guinea-Bissau": 94,
                "Guyana": 95,
                "Haiti": 96,
                "Heard Island and McDonald Islands": 97,
                "Honduras": 98,
                "Hong Kong S.A.R.": 99,
                "Hungary": 100,
                "Iceland": 101,
                "India": 102,
                "Indonesia": 103,
                "Iran": 104,
                "Iraq": 105,
                "Ireland": 106,
                "Israel": 107,
                "Italy": 108,
                "Jamaica": 109,
                "Japan": 110,
                "Jersey": 111,
                "Jordan": 112,
                "Kazakhstan": 113,
                "Kenya": 114,
                "Kiribati": 115,
                "Kosovo": 116,
                "Kuwait": 117,
                "Kyrgyzstan": 118,
                "Laos": 119,
                "Latvia": 120,
                "Lebanon": 121,
                "Lesotho": 122,
                "Liberia ": 123,
                "Libya": 124,
                "Liechtenstein": 125,
                "Lithuania": 126,
                "Luxembourg": 127,
                "Macau S.A.R.": 128,
                "Macedonia": 129,
                "Madagascar": 130,
                "Malawi": 131,
                "Malaysia": 132,
                "Maldives": 133,
                "Mali": 134,
                "Malta": 135,
                "Man (Isle of)": 136,
                "Marshall Islands": 137,
                "Martinique": 138,
                "Mauritania": 139,
                "Mauritius": 140,
                "Mayotte": 141,
                "Mexico": 142,
                "Micronesia": 143,
                "Moldova": 144,
                "Monaco": 145,
                "Mongolia": 146,
                "Montenegro": 147,
                "Montserrat": 148,
                "Morocco": 149,
                "Mozambique": 150,
                "Myanmar": 151,
                "Namibia": 152,
                "Nauru": 153,
                "Nepal": 154,
                "Netherlands": 155,
                "New Caledonia": 156,
                "New Zealand": 157,
                "Nicaragua": 158,
                "Niger": 159,
                "Nigeria": 160,
                "Niue": 161,
                "Norfolk Island": 162,
                "North Korea": 163,
                "Northern Mariana Islands": 164,
                "Norway": 165,
                "Oman": 166,
                "Pakistan": 167,
                "Palau": 168,
                "Palestinian Territory Occupied": 169,
                "Panama": 170,
                "Papua new Guinea": 171,
                "Paraguay": 172,
                "Peru": 173,
                "Philippines": 174,
                "Pitcairn Island": 175,
                "Poland": 176,
                "Portugal": 177,
                "Puerto Rico": 178,
                "Qatar": 179,
                "Reunion": 180,
                "Romania": 181,
                "Russia": 182,
                "Rwanda": 183,
                "Saint Helena": 184,
                "Saint Kitts And Nevis": 185,
                "Saint Lucia": 186,
                "Saint Pierre and Miquelon": 187,
                "Saint Vincent And The Grenadines": 188,
                "Saint-Barthelemy": 189,
                "Saint-Martin (French part)": 190,
                "Samoa": 191,
                "San Marino": 192,
                "Sao Tome and Principe": 193,
                "Saudi Arabia": 194,
                "Senegal": 195,
                "Serbia": 196,
                "Seychelles": 197,
                "Sierra Leone": 198,
                "Singapore": 199,
                "Sint Maarten (Dutch part)": 200,
                "Slovakia": 201,
                "Slovenia": 202,
                "Solomon Islands": 203,
                "Somalia": 204,
                "South Africa": 205,
                "South Georgia": 206,
                "South Korea": 207,
                "South Sudan": 208,
                "Spain": 209,
                "Sri Lanka": 210,
                "Sudan": 211,
                "Suriname": 212,
                "Svalbard And Jan Mayen Islands": 213,
                "Swaziland": 214,
                "Sweden": 215,
                "Switzerland": 216,
                "Syria": 217,
                "Taiwan": 218,
                "Tajikistan": 219,
                "Tanzania": 220,
                "Thailand": 221,
                "The Bahamas": 222,
                "Togo": 223,
                "Tokelau": 224,
                "Tonga": 225,
                "Trinidad And Tobago": 226,
                "Tunisia": 227,
                "Turkey": 228,
                "Turkmenistan": 229,
                "Turks And Caicos Islands": 230,
                "Tuvalu": 231,
                "Uganda": 232,
                "Ukraine": 233,
                "United Arab Emirates": 234,
                "United Kingdom": 235,
                "United States": 236,
                "United States Minor Outlying Islands": 237,
                "Uruguay": 238,
                "Uzbekistan": 239,
                "Vanuatu": 240,
                "Vatican City State (Holy See)": 241,
                "Venezuela": 242,
                "Vietnam": 243,
                "Virgin Islands (British)": 244,
                "Virgin Islands (US)": 245,
                "Wallis And Futuna Islands": 246,
                "Western Sahara": 247,
                "Yemen": 248,
                "Zambia": 249,
                "Zimbabwe": 250
            };
            const codes = response.data.map(country => {
              const predefinedCode = predefinedCountryCodes[country.name.common];
             const code = predefinedCode || `${country.idd.root}${country.idd.suffixes ? country.idd.suffixes[0] : ''}`;
             return {
                 code,
                 name: country.name.common,
                 flag: country.flags.svg,
                 id: countryIdMap[country.name.common] || country.ccn3
             };
         });
         console.log("Testcodes", codes);
         setcountryCodes(codes);
     } catch (e) {
         console.error(e);
     }
 };
 fetchCountryCodes();
}, []);

const predefinedCountryCodes = {
 'United States': '+1',
 'Canada': '+1',
 // Add more predefined codes as needed
};
    useEffect(() => {
      setstateList(fetchacompanyReducer?.GetstateResponse)
  }, [fetchacompanyReducer?.GetstateResponse])

  useEffect(() => {
      setcityList(fetchacompanyReducer?.GetcityResponse)
  }, [fetchacompanyReducer?.GetcityResponse])

  const CountryHandle = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryCode = countryCodes.find(c => c.name === selectedCountry)?.code || '';
    const selectedCountryId = countryCodes.find(c => c.name === selectedCountry)?.id || '';
    let obj = {
        id: selectedCountryId // Use the id value from the countryCodes array
    };
    setcountryId(selectedCountryId);
    dispatch(GetStateRequest(obj));
    setcountry(selectedCountry);
    console.log(selectedCountry)
    setcountryCode(selectedCountryCode);
};

  const stateHandle = (e) => {
      setstate(e.target.value)
      setiserror('')
      let obj = {
          id: e.target.value
      }
      dispatch(GetcityRequest(obj))
  }


    const SubmitHandle = () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    
        if (location == '') {
          Swal.fire({
            icon: "error",
            text: 'Location should be must',
          });
        } 
         else if (phone == '') {
          Swal.fire({
            icon: "error",
            text: 'Phone no should be must',
          });
        } else if (reg.test(email) === false) {
          Swal.fire({
            icon: "error",
            text: 'Email should be proper',
          });
        } else if (date == '') {
          Swal.fire({
            icon: "error",
            text: 'Date should be must',
          });
        } else if (status == '') {
          Swal.fire({
            icon: "error",
            text: 'Status should be must',
          });
        } else if (url == '') {
          Swal.fire({
            icon: "error",
            text: 'Url should be must',
          });
        } else if (addressone == '') {
          Swal.fire({
            icon: "error",
            text: 'Address 1 should be must',
          });
        } else if (country == '') {
          Swal.fire({
            icon: "error",
            text: 'Country should be must',
          });
        } else if (state == '') {
          Swal.fire({
            icon: "error",
            text: 'State should be must',
          });
        } else if (city == '') {
          Swal.fire({
            icon: "error",
            text: 'City should be must',
          });
        } else if (zipcode == '') {
          Swal.fire({
            icon: "error",
            text: 'Zipcode should be must',
          });
        }
    
    
        else {
          let obj = {
            "employee_id": userinfo?.user_type == "COMPANY" ? "" : userinfo.user_id,
            "location_name": location,
            "location_title": '',
            "first_name": '',
            "last_name": '',
            "email": email,
            "phone": countryCode + phone,
            "date":moment(date).format("YYYY-MM-DD") ,
            "status": status,
            "url": url,
            "is_virtual_location": rejected == false ? 0 : 1,
            "street_address_1": addressone,
            "street_address_2": addresstwo,
            "country_id": Number(countryId),
            "state_id": Number(state),
            "city_id": Number(city),
            "zipcode": Number(zipcode),

          }
    
          dispatch(AddlocationRequest({ obj: obj, navigate: navigate }))
        }
      }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Add Location</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>
          <div className='col-lg-12 mb-2'>
            <h6>Add Location Details</h6>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Location Name <span class="asterisk">*</span></label>
              <input type='text' className='form-control' placeholder='Location Name'
                value={location}
                onChange={(e) => setlocation(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Address 1 <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control ${iserror == 'addressone' ? 'error' : ''}`} placeholder='Street Address 1'
                                value={addressone}
                                onChange={(e) => { setaddressone(e.target.value); setiserror('') }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Address 2 </label>
                            <input type='text' className='form-control' placeholder='Street Address 2'
                                value={addresstwo}
                                onChange={(e) => setaddresstwo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Country <span className="asterisk">*</span></label>
                            <select className={`form-control ${iserror === 'country' ? 'error' : ''}`} value={country} onChange={CountryHandle}>
                                <option value="">--Select country---</option>
                                {countryCodes
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((list, i) => (
                                        <option key={i} value={list.name}>{list.name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select State <span class="asterisk">*</span> </label>
                            <select className={`form-control ${iserror == 'state' ? 'error' : ''}`} value={state}
                                onChange={stateHandle}>
                                <option value="">--Select State---</option>

                                {stateList && stateList.map((list, i) => {
                                    return (
                                        <option key={i} value={list?.state_id
                                        }>{list?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select City <span class="asterisk">*</span></label>
                            <select className={`form-control ${iserror == 'city' ? 'error' : ''}`} value={city}
                                onChange={(e) => { setcity(e.target.value); setiserror('') }}>
                                <option value="">--Select city---</option>
                                {cityList && cityList.map((list, i) => {
                                    return (
                                        <option key={i} value={list?.city_id
                                        }>{list?.name}</option>
                                    )

                                })}

                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Zipcode <span class="asterisk">*</span> </label>
                            <input type='text' className={`form-control ${iserror == 'zipcode' ? 'error' : ''}`} placeholder='Zipcode'
                                value={zipcode}
                                onChange={(e) => { setzipcode(e.target.value); setiserror('') }}
                                maxLength="6"
                                minLength="6"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Location Email <span class="asterisk">*</span></label>
              <input type='email' className='form-control' placeholder='Location Email'
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Location Phone <span class="asterisk">*</span></label>
            <div className='d-flex'>
                                <div className='col-lg-4'>
                                    <select
                                        className='form-control' style={{ width: '140%', marginLeft: '-15px', padding: '11px 0px', fontSize: '12px' }}
                                        value={countryCode} disabled
                                        onChange={(e) => setcountryCode(e.target.value)}
                                    >
                                        {countryCodes.map((country) => (
                                            <option key={country.code} value={country.code}>
                                                {country.code} ({country.name})
                                            </option>
                                        ))}
                                    </select>
                                </div>
              <input type='text' className='form-control' placeholder='Location Phone'
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                maxLength="10"
                minLength="10"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Select Opening Date <span class="asterisk">*</span></label>
           <DatePicker selected={date} onChange={(date) => setdate(date)} className='form-control'
           placeholderText="Opening date"
           />
           </div>
       </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Status <span class="asterisk">*</span></label>
              <select className='form-control' value={status}
                onChange={(e) => setstatus(e.target.value)}>
                <option value="">--Status---</option>
                <option value="active">Active</option>
                <option value="inactive">In-active</option>
              </select>
            </div>
          </div>


          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Enter URL <span class="asterisk">*</span></label>
              <input type='text' className='form-control' placeholder='url' value={url}
                onChange={(e) => seturl(e.target.value)} />
            </div>
          </div>

          <div className='col-lg-4' style={{marginTop: '10px', marginLeft: '4px'}}>
                        <div className='form-group'>
                            <input type="checkbox" defaultChecked={rejected}
                                onChange={() => setrejected(!rejected)} /> Is Hybrid Option Enabled
                        </div>

                    </div>

          {/* <div className='col-lg-12'>
                        <hr />
                    </div>
                    <div className='col-lg-12 mb-2'>
                        <h6>Address information</h6>
                    </div> */}


        </div>
        <button className='formBtn' onClick={SubmitHandle}>Save</button>

            </div>
        </div>
    )

}

export default AddLocation