import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, ExportstudentRequest, GetStudentListRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';



const StudentsList = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show, setShow] = useState(false);

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var studentList = fetchacompanyReducer?.GetStudentListResponse?.results

    console.log(fetchacompanyReducer.GetStudentListResponse)

    const dispatch = useDispatch()

    const handleClose = () => setShow(false);


    const [traininfo, settraininfo] = useState([{
        label: '',
        assigndate: null,
        duedate: null,
        completedate: null,
        isdisabled: false
    }]);

    const handleShow = () => {
        setShow(true)
        setColumnName(column)
        const link = document.getElementById("Download");
        link.removeAttribute("href");
    };

    const column = [
        {
          "key": "First Name",
          "value":"First_Name",
          "ischecked":false
        },
        {
          "key": "Last Name",
          "value":"Last_Name",
          "ischecked":false
        },
        {
          "key": "Gender",
          "value":"gender",
          "ischecked":false
        },
        {
          "key": "Grade",
          "value":"grade_name",
          "ischecked":false
        },
        {
          "key": "School",
          "value":"SVM Public School",
          "ischecked":false
        },
        {
          "key": "School Year",
          "value":"school_year",
          "ischecked":false
        },
        {
          "key": "Teacher",
          "value":"Arnab",
          "ischecked":false
        },
        {
          "key": "Description",
          "value":"student_description",
          "ischecked":false
        },
        {
            "key": "Medical Information",
            "value":"medical_information",
            "ischecked":false
          },
          {
            "key": "School Subject Matter",
            "value":"school_subject_matter",
            "ischecked":false
          },
          {
            "key": "Student Notes",
            "value":"students_notes",
            "ischecked":false
          },
          {
            "key": "Notes From Center Director",
            "value":"notes_from_center_director",
            "ischecked":false
          },
        
      ]

    const columns = [
        {
            name: 'Student Name',
            selector: row => row.first_name + ' ' + row.last_name,
            sortable: true,
        },
        {
            name: 'Grade',
            selector: row => row.grade_name,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.school_name,
            sortable: true,
        },
        // {
        //     name: 'Teacher',
        //     selector: row => 'Arnab',
        //     sortable: true,
        // },
        {
            name: 'School Year',
            selector: row => row.school_year,
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.virtual_loocation,
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => (
                <>
                    <NavLink to={`/${userinfo?.company_slug}/students/view-students/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                    {/* <NavLink to={`/${userinfo?.company_slug}/students/edit-students/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink> */}
                    <button onClick={() => DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
                </>
            ),
        },
    ];

    const [columnName, setColumnName] = useState(column)


    useEffect(() => {
        const result = studentList && studentList.filter(data => {
            return data.first_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })


        setFilterResult(result)
    }, [Namesearch])

    useEffect(()=>{

        setFilterResult(studentList)
      },[fetchacompanyReducer?.GetStudentListResponse])

    useEffect(() => {
        dispatch(GetStudentListRequest())
    }, [])

    useEffect(() => {
        dispatch(GetStudentListRequest())
    }, [fetchacompanyReducer?.DeletestudentResponse])

    const dateHandle = (date, key, index) => {
        console.log(index)
        var newValue = [...traininfo];
        newValue[index][key] = date
        settraininfo(newValue);
    }

    const SearchHandle = () => {
        let obj = {
            "page_size": 10,
            'page': 1,
            //   'name':name,
            //   'status':status,
            //   'location_id':location
        }

        dispatch(ViewstudentRequest(obj))
    }

    const ResetHandle = () => {
        setNamesearch("")
        setsearch("")
        let obj = {
            "page_size": 10,
            'page': 1,
            'name': '',
            'status': '',
            'location_id': ''
        }

        dispatch(ViewstudentRequest(obj))
    }

    const CheckboxHandle = (index, e) => {
        const { value, checked } = e.target;
        let raw = [...columnName]
        if (checked) {
            raw[index].ischecked = true
        } else {
            raw[index].ischecked = false
        }
        setColumnName(raw)
    }

    const ExportHandle = () => {

        let Arr = []

        columnName.forEach(element => {

            if (element.ischecked) {
                Arr.push(element.value)
            }

        });

        let obj = {
            "column_selector": Arr
        }

        if (obj.column_selector.length > 1) {
            dispatch(ExportstudentRequest(obj))
            setShow(false)
        } else {
            Swal.fire({
                icon: "error",
                text: 'Choose atleast two columns',
            });
        }


    }


    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeletestudentRequest(id))

                if (fetchacompanyReducer?.DeletestudentResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }



    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Student Management</h3>
                </div>

                <div className='row form-style mb-4 align-items-center'>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Student Name</label>
                            <input type='text' className='form-control' placeholder='Search Student Name..'
                                value={Namesearch}
                                onChange={(e) => setNamesearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Prospect/Customer</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Enrollment Status</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">In-Active</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Start</label>
                            <DatePicker onChange={(date) => dateHandle(date, 'duedate')} className='form-control'
                                placeholderText="MM/DD/YYYY"
                            />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>End</label>
                            <DatePicker onChange={(date) => dateHandle(date, 'duedate')} className='form-control'
                                placeholderText="MM/DD/YYYY"
                            />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Delivery</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='d-block'>&nbsp;</label>
                            <div className='d-flex'>
                                {/* <button className='btn btn-primary mr-3' onClick={SearchHandle}>Search</button> */}
                                <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='button-part mb-4'>
                    <ul>
                        {/* <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/students/add-students`}><i className="fa-solid fa-plus"></i> Add Student</NavLink>
                        </li> */}
                        <li>
                            <button className='formBtn1' onClick={handleShow}><i className="fa-solid fa-file-excel"></i> Export </button>
                        </li>
                    </ul>
                </div>
                <a id="Download" download></a>

                <div className='table-responsive custom-table'>
                    <DataTable
                        columns={columns}
                        data={FilterResult}
                        pagination
                        highlightOnHover
                    // subHeader
                    // subHeaderComponent={
                    //   <input type="text" 
                    //   className='w-25 form-control' 
                    //   placeholder='Search Here'
                    //   value={search}
                    //   onChange={(e)=>setsearch(e.target.value)}
                    //   />
                    // }
                    />

                </div>



            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Export CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className='mb-3'>Select Column</h6>
                    <ul className='columnlist'>
                        {columnName.map((column, index) => {
                            return (
                                <li key={index}>
                                    <input type="checkbox" name="column" id={`column${index}`} value={column.value} onChange={(e) => CheckboxHandle(index, e)} />
                                    <label htmlFor={`column${index}`}>{column.key}</label>
                                </li>
                            )

                        })}

                    </ul>

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-warning' onClick={handleClose}>
                        Close
                    </button>
                    <button className='btn btn-primary' onClick={ExportHandle}>
                        Export Now
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StudentsList