import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { UpdateTitleRequest, FetchTitleRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ApiConnection from '../../../utils/ApiConnection'

const EditEmail_Template = () => {

    const [docId, setdocId] = useState(null);
    const [emailsubject, setemailsubject] = useState('');
    const [emailbody, setemailbody] = useState('<p>Enter Text</p>');
    const [emailnotes, setemailnotes] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [emailsubList, setemailsubList] = useState([]);
    const [emailsub, setemailsub] = useState('');
    const [templateType, setTemplateType] = useState('email');
    const [tags, setTags] = useState(['prospect_name', 'student_name', 'test_name', 'user_type']);

    const [tname, settname] = useState("")
    const [changepaymentmethodrequestemail, setchangepaymentmethodrequestemail] = useState("")

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var editdata = fetchacompanyReducer.FetchTitleResponse?.results[0]

    console.log(fetchacompanyReducer.FetchTitleResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const {id} = useParams()
    
    useEffect(() => {
        Getemaildata()
    }, [])

    const handleCheckboxChange = () => {
        const emailRadio = document.getElementById('email');
        const smsRadio = document.getElementById('sms');
        const emailTemplateSubject = document.getElementById('emailTemplateSubject');

        if (smsRadio.checked) {
            emailTemplateSubject.style.display = 'none';
            setTemplateType('sms');
        } else {
            emailTemplateSubject.style.display = 'block';
            setTemplateType('email');
        }
    };

    const Tagshandle = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const value = selectedOption.value;
        const msgType = selectedOption.text;

        setemailsub(value);

        if (msgType === 'Account Create') {
            setTags(['user_type', 'email']);
        } else if (msgType === 'Exam Taken') {
            setTags(['test_name','student_name', 'exam_date']);
        } else if (msgType === 'Subscriptions Done') {
            setTags(['prospect_name','student_name']);
        } else if (msgType === 'Assessment Assign') {
            setTags(['student_name', 'assessment_name']);
        } else if (msgType === 'Prospect Conversion') {
            setTags(['prospect_name', 'guardian_name']);
        } else if (msgType === 'Account Email Update') {
            setTags(['user_type', 'email']);
        } else if (msgType === 'Forget Password') {
            setTags(['user_type', 'email']);
        } else {
            setTags(['exam_date', 'prospect_name', 'guardian_name', 'student_name', 'email', 'test_name', 'user_type']);
        }
    };

    const Getemaildata = async () => {

        try {
            const response = await ApiConnection.get(`email_template_crud/?server_type=production&id=${id}`)

            setemailsub(response.data.results[0].email_master_id);
            setemailbody(response.data.results[0].email_body);
            setemailsubject(response.data.results[0].email_subject);

        } catch (e) { }
    };

    const editorRef = useRef(null);

    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo'],
        contentsLangDirection: 'ltr'
    };

    const changeTemplateHandle = (editor) => {
        const data = editor.getData();
        setemailbody(data);
    };

    const handleTagClick = (tag) => {
        const editor = editorRef.current;
        if (editor) {
            const wrappedTag = ` {${tag}} `;
            const viewFragment = editor.data.processor.toView(wrappedTag);
            const modelFragment = editor.data.toModel(viewFragment);

            editor.model.change(writer => {
                editor.model.insertContent(modelFragment, editor.model.document.selection);
            });
        } else {
            console.error('Editor instance is not available.');
        }
    };

    useEffect(() => {
        GetEmailSubject()
    }, [])

    const GetEmailSubject = async () => {
        try {
            const response = await ApiConnection.get(`email_template_master_crud/?server_type=production`)
            if (response?.status == 200) {
                setemailsubList(response?.data?.results)
            }
        } catch (e) { }
    }

    const UpdateHandle = async () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        let obj = {
            "email_subject": emailsubject,
            "email_body": emailbody,
            "email_notes": emailnotes,
            "email_master_id": emailsub,
            "template_type": templateType

        }

            try {
                const response = await ApiConnection.put(`email_template_crud/?server_type=production&id=${id}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    // GetDiscount()
                }
            } catch (e) { }
    }


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> Edit Message Template</h3>
      </div>
      <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>

<div className='col-lg-12'>
<div className='d-flex flex-column col-lg-4' style={{ flexDirection: 'column' }}>
                            <div className='col-lg-12' >
                                <label className='selectlabel' style={{ margin: '0 -14px 4px -14px' }} >Template Type <span class="asterisk">*</span></label>
                            </div>
                            <div className='d-flex' style={{ border: '2px solid #b9b9b9', padding: '15px 0px 0px 0px', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>
                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <input type="radio" name="templateType" id="email" value="email" onChange={handleCheckboxChange} />
                                        <label for="email" style={{ marginLeft: '5px' }}>Email</label>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <input type="radio" name="templateType" id="sms" value="sms" onChange={handleCheckboxChange} />
                                        <label for="sms" style={{ marginLeft: '5px' }}>Sms</label>
                                    </div>
                                </div>
                            </div>
                        </div>
    <div className='form-group'>
        <div className='d-flex'>
        <div className='col-lg-4'>
                                    <label className='selectlabel'>Message Type <span class="asterisk">*</span></label>
                                    <select className='form-control' value={emailsub} onChange={Tagshandle}>
                                        <option value="">--Select Message Type---</option>
                                        {emailsubList && emailsubList.map((item, i) => (
                                            <option key={i} value={item?.id}>{item?.title}</option>
                                        ))}
                                    </select>
                                </div>
        <div className='col-lg-8' id="emailTemplateSubject">
            <label className='selectlabel'>Email Template Subject <span class="asterisk">*</span></label>
            <input type='text' className='form-control' placeholder='Enter Text'
                value={emailsubject}
                onChange={(e) => setemailsubject(e.target.value)}
            />
        </div>
        </div>
        <br></br>
        <div className="form-control custom1">
                                <label className="taglevel">Select Tags : </label>
                                <ul className="taglistul">
                                    {tags.map(tag => (
                                        <li key={tag} className="taglistli" onClick={() => handleTagClick(tag)}>{tag}</li>
                                    ))}
                                </ul>
                            </div>
                            <CKEditor
                                editor={Editor}
                                config={editorConfiguration}
                                data={emailbody}
                                onReady={editor => {
                                    editorRef.current = editor;
                                }}
                                onChange={(event, editor) => changeTemplateHandle(editor)}
                            />
    </div>
</div>

</div>
   <button className='formBtn' onClick={UpdateHandle}>Update</button>
    </div>

  </div>
  )
}

export default EditEmail_Template