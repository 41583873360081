import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects'
import { getApi, postApi, getApiWithParam, putApi, patchApi } from '../../utils/ApiRequest';
import Swal from "sweetalert2";

import {
  GetProspectiveSuccess,
  GetProspectiveFailure,
  GetcountrySuccess,
  GetcountryFailure,
  SignInCompanySuccess,
  SignInCompanyFailure,
  GetLocationSuccess,
  GetLocationFailure,
  GetInterestSuccess,
  GetInterestFailure,
  GetstateSuccess,
  GetstateFailure,
  GetcitySuccess,
  GetcityFailure,
  AddpropectsSuccess,
  AddpropectsFailure,
  ViewprospectsSuccess,
  ViewprospectsFailure,
  ExportProspectiveSuccess,
  ExportProspectiveFailure,
  UpdatepropectsSuccess,
  UpdatepropectsFailure,
  GetEmployeeSuccess,
  GetEmployeeFailure,
  AddemployeeSuccess,
  AddemployeeFailure,
  GetTitleSuccess,
  GetTitleFailure,
  FetchTitleSuccess,
  FetchTitleFailure,
  AddTitleSuccess,
  AddTitleFailure,
  UpdateTitleSuccess,
  UpdateTitleFailure,
  DeleteTitleSuccess,
  DeleteTitleFailure,
  GetManagerSuccess,
  GetManagerFailure,
  GetBackgroundListSuccess,
  GetBackgroundListFailure,
  GetBackgroundSuccess,
  GetBackgroundFailure,
  AddBackgroundSuccess,
  AddBackgroundFailure,
  DeleteBccSuccess,
  DeleteBccFailure,
  FetchBccSuccess,
  FetchBccFailure,
  GetTrainingSuccess,
  GetTrainingFailure,
  GetTrainingListSuccess,
  GetTrainingListFailure,
  AddTrainingSuccess,
  AddTrainingFailure,
  FetchTrainingSuccess,
  FetchTrainingFailure,
  DeleteTrainingSuccess,
  DeleteTrainingFailure,
  UpdateTrainingSuccess,
  UpdateTrainingFailure,
  GetGroupSuccess,
  GetGroupFailure,
  AddGroupSuccess,
  AddGroupFailure,
  FetchGroupSuccess,
  FetchGroupFailure,
  DeleteGroupSuccess,
  DeleteGroupFailure,
  UpdateGroupSuccess,
  UpdateGroupFailure,
  GetGradeSuccess,
  GetGradeFailure,
  AddGradeSuccess,
  AddGradeFailure,
  FetchGradeSuccess,
  FetchGradeFailure,
  UpdateGradeFailure,
  UpdateGradeSuccess,
  DeleteGradeSuccess,
  DeleteGradeFailure,
  GetSubjectSuccess,
  GetSubjectFailure,
  GetAllGroupListSuccess,
  GetAllGroupListFailure,
  AddSubjectFailure,
  AddSubjectSuccess,
  FetchSubjectSuccess,
  FetchSubjectFailure,
  UpdateSubjectSuccess,
  UpdateSubjectFailure,
  DeleteSubjectSuccess,
  DeleteSubjectFailure,
  ViewEmployeeSuccess,
  ViewEmployeeFailure,
  UpdateEmployeeSuccess,
  UpdateEmployeeFailure,
  DeleteEmployeeSuccess,
  DeleteEmployeeFailure,
  GetSyllabusSuccess,
  GetSyllabusFailure,
  AddSyllabusSuccess,
  AddSyllabusFailure,
  FetchSyllabusSuccess,
  FetchSyllabusFailure,
  GetModuleSuccess,
  GetModuleFailure,
  AddModuleSuccess,
  AddModuleFailure,
  GetTypeSuccess,
  GetTypeFailure,
  UpdateSyllabusSuccess,
  UpdateSyllabusFailure,
  FetchModuleSuccess,
  FetchModuleFailure,
  UpdateModuleSuccess,
  UpdateModuleFailure,
  DeleteModuleSuccess,
  DeleteModuleFailure,
  DeleteSyllabusSuccess,
  DeleteSyllabusFailure,
  DeleteprospectsSuccess,
  DeleteprospectsFailure,
  GetTopicSuccess,
  GetTopicFailure,
  AddTopicSuccess,
  AddTopicFailure,
  FetchTopicSuccess,
  FetchTopicFailure,
  DeleteTopicSuccess,
  DeleteTopicFailure,
  UpdateTopicSuccess,
  UpdateTopicFailure,
  AddguardianSuccess,
  AddguardianFailure,
  ViewguardianSuccess,
  ViewguardianFailure,
  ExportguardianSuccess,
  ExportguardianFailure,
  UpdateguardianSuccess,
  UpdateguardianFailure,
  DeleteguardianSuccess,
  DeleteguardianFailure,
  AddlocationSuccess,
  AddlocationFailure,
  ViewlocationSuccess,
  ViewlocationFailure,
  ExportlocationSuccess,
  ExportlocationFailure,
  UpdatelocationSuccess,
  UpdatelocationFailure,
  DeletelocationSuccess,
  DeletelocationFailure,
  FetchlocationSuccess,
  FetchlocationFailure,
  GetGuardianSuccess,
  GetGuardianFailure,
  GetlocationsSuccess,
  GetlocationsFailure,
  GetCrudlocationSuccess,
  GetCrudlocationFailure,
  MakePrimarySuccess,
  MakePrimaryFailure,
  GetEmployeelocationSuccess,
  GetEmployeelocationFailure,
  AddEmployeelocationSuccess,
  AddEmployeelocationFailure,
  FetchEmployeelocationSuccess,
  FetchEmployeelocationFailure,
  DeleteEmployeelocationSuccess,
  DeleteEmployeelocationFailure,
  UpdateEmployeelocationSuccess,
  UpdateEmployeelocationFailure,
  MakeLocationPrimarySuccess,
  MakeLocationPrimaryFailure,

  AddstudentSuccess,
  AddstudentFailure,
  ViewstudentSuccess,
  ViewstudentFailure,
  ExportstudentSuccess,
  ExportstudentFailure,
  UpdatestudentSuccess,
  UpdatestudentFailure,
  DeletestudentSuccess,
  DeletestudentFailure,
  FetchstudentSuccess,
  FetchstudentFailure,
  GetstudentSuccess,
  GetstudentFailure,
  GetCrudstudentSuccess,
  GetCrudstudentFailure,
  GetDefaultlocationsSuccess,
  GetDefaultlocationsFailure,
  GetModuleTypeSuccess,
  GetModuleTypeFailure,
  AddModuleTypeSuccess,
  AddModuleTypeFailure,
  DeleteModuleTypeSuccess,
  DeleteModuleTypeFailure,
  AddtestSuccess,
  AddtestFailure,
  GettestSuccess,
  GettestFailure,
  UpdatetestSuccess,
  UpdatetestFailure,
  FetchtestSuccess,
  FetchtestFailure,
  DeletetestSuccess,
  DeletetestFailure,
  GetquesSuccess,
  GetquesFailure,
  GetAssignEmployeelocationSuccess,
  GetAssignEmployeelocationFailure,
  AddTeacherTrainingSuccess,
  AddTeacherTrainingFailure,
  GetTeacherTrainingSuccess,
  GetTeacherTrainingFailure,
  UpdateTeacherTrainingSuccess,
  UpdateTeacherTrainingFailure,
  DeleteTeacherTrainingSuccess,
  DeleteTeacherTrainingFailure,
  GetProfileSuccess,
  GetProfileFailure,
  UpdateProfileSuccess,
  UpdateProfileFailure,
  FetchTeacherTrainingSuccess,
  FetchTeacherTrainingFailure,
  GetSubjectChangeSuccess,
  GetSubjectChangeFailure,
  GetTopicChangeSuccess,
  GetTopicChangeFailure,
  GetModuleChangeSuccess,
  GetModuleChangeFailure,
  GetSyllabusChangeSuccess,
  GetSyllabusChangeFailure,
  UpdateorderListSuccess,
  UpdateorderListFailure,
  FetchQuestionSuccess,
  FetchQuestionFailure,
  GetSyllabusTopicSuccess,
  GetSyllabusTopicFailure,
  DownloadQuestionSuccess,
  DownloadQuestionFailure,
  AssignScheduleSuccess,
  AssignScheduleFailure,
  ViewQuestionFailure,
  ViewQuestionSuccess,
  UpdateQuestionSuccess,
  UpdateQuestionFailure,
  DeleteQuestionSuccess,
  DeleteQuestionFailure,
  AssignGroupScheduleSuccess,
  AssignGroupScheduleFailure,
  GetGradeStudentSuccess,
  GetGradeStudentFailure,
  GetTeacherActivitySuccess,
  GetTeacherActivityFailure,
  DeleteTeacherActivitySuccess,
  DeleteTeacherActivityFailure,
  FetchTeacherActivitySuccess,
  FetchTeacherActivityFailure,
  AddTeacherActivitySuccess,
  AddTeacherActivityFailure,
  UpdateTeacherActivitySuccess,
  UpdateTeacherActivityFailure,
  ExportEmployeeSuccess,
  ExportEmployeeFailure,
  UpdateDocumentSuccess,
  UpdateDocumentFailure,
  DeleteDocumentSuccess,
  DeleteDocumentFailure,
  GetDocumentSuccess,
  GetDocumentFailure,
  AddDocumentSuccess,
  AddDocumentFailure,
  FetchDocumentSuccess,
  FetchDocumentFailure,
  AddattendanceSuccess,
  AddattendanceFailure,
  GetattendanceSuccess,
  GetattendanceFailure,
  UpdateattendanceSuccess,
  UpdateattendanceFailure,
  FetchattendanceSuccess,
  FetchattendanceFailure,
  DeleteattendanceSuccess,
  DeleteattendanceFailure,
  GetEmployeevirtuallocationSuccess,
  GetEmployeevirtuallocationFailure,
  AddEmployeevirtuallocationSuccess,
  AddEmployeevirtuallocationFailure,
  FetchEmployeevirtuallocationSuccess,
  FetchEmployeevirtuallocationFailure,
  DeleteEmployeevirtuallocationSuccess,
  DeleteEmployeevirtuallocationFailure,
  UpdateEmployeevirtuallocationSuccess,
  UpdateEmployeevirtuallocationFailure,
  GettestfilterSuccess,
  GettestfilterFailure,
  GetExistingSuccess,
  GetExistingFailure,
  GetExistingTestSuccess,
  GetExistingTestFailure,
  AddExistingSuccess,
  AddExistingFailure,
  GetAttendenceSuccess,
  GetAttendenceFailure,
  AddpunchSuccess,
  AddpunchFailure,
  GetTeacherSuccess,
  GetTeacherFailure,
  AddTeacherSuccess,
  AddTeacherFailure,
  FetchTeacherSuccess,
  FetchTeacherFailure,
  UpdateTeacherSuccess,
  UpdateTeacherFailure,
  DeleteTeacherSuccess,
  DeleteTeacherFailure,
  GetSchoolSuccess,
  GetSchoolFailure,
  AddSchoolSuccess,
  AddSchoolFailure,
  FetchSchoolSuccess,
  FetchSchoolFailure,
  UpdateSchoolSuccess,
  UpdateSchoolFailure,
  DeleteSchoolSuccess,
  DeleteSchoolFailure,
  GetStudentActivitySuccess,
  GetStudentActivityFailure,
  AddStudentActivitySuccess,
  AddStudentActivityFailure,
  FetchStudentActivitySuccess,
  FetchStudentActivityFailure,
  UpdateStudentActivitySuccess,
  UpdateStudentActivityFailure,
  DeleteStudentActivitySuccess,
  DeleteStudentActivityFailure,
  GetStudenttestSuccess,
  GetStudenttestFailure,
  AddStudentAssessmentSuccess,
  AddStudentAssessmentFailure,
  GetStudentTestListSuccess,
  GetStudentTestListFailure,
  GetAssessmentAnswerSuccess,
  GetAssessmentAnswerFailure,
  GetAssessmentResultSuccess,
  GetAssessmentResultFailure,
  GetResultListSuccess,
  GetResultListFailure,
  GetCompareChartSuccess,
  GetCompareChartFailure,
  GetResultChartSuccess,
  GetResultChartFailure,
  UpdateAssessmentResultSuccess,
  UpdateAssessmentResultFailure,
  GetLearningPlanSuccess,
  GetLearningPlanFailure,
  ViewLearningPlanSuccess,
  ViewLearningPlanFailure,
  GetLearningPlanListsSuccess,
  GetLearningPlanListsFailure,
  DownloadLearningPlanSuccess,
  DownloadLearningPlanFailure,
  ShuffleLearningPathSuccess,
  ShuffleLearningPathFailure,
  GetStudentListSuccess,
  GetStudentListFailure,
  GetProgressCheckSuccess,
  GetProgressCheckFailure,
  AddProgressCheckSuccess,
  AddProgressCheckFailure,
  GetProgressReportSuccess,
  GetProgressReportFailure,
  GetLearningPathMainSuccess,
  GetLearningPathMainFailure,
  AddServicesSuccess,
  AddServicesFailure,
  AddPricingSuccess,
  AddPricingFailure,
  AddStaffSuccess,
  AddStaffFailure,
  AddServiceSubjectSuccess,
  AddServiceSubjectFailure,
  AddStudentEnrollSuccess,
  AddStudentEnrollFailure,


} from '../reducer/FetchCompanyDataReducer';
import { act } from 'react';

const token = sessionStorage.getItem('mtb_access_token');
var userinfo = JSON.parse(localStorage.getItem('userinfo'))


function* LoginCompanyRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
  };

  try {
    let response = yield call(postApi, 'users/login/', action.payload.obj, header);
    if (response.data.success) {
      if (response?.data?.data?.token != null) {
        sessionStorage.setItem('mtb_access_token', response?.data?.data?.token);
        localStorage.setItem('userinfo', JSON.stringify(response?.data?.data));
      }
      yield put(SignInCompanySuccess(response.data))
      //action.payload.navigate(`/${action.payload.company_slug}/dashboard`)
      window.location.reload();
    } else {
      yield put(SignInCompanyFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    yield put(SignInCompanyFailure(err.data))

    Swal.fire({
      icon: "error",
      text: err.response?.data?.detail,
    });
  }


}


function* GetProspectiveRequest(action) {

  var request = ""
  if (action.payload.name !== '' || action.payload.status !== '' || action.payload.location_id !== '' || action.payload.employee_id !== '') {
    request = `&name=${action.payload.name}&status=${action.payload.status}&location_id=${action.payload.location_id}&employee_id=${action.payload.employee_id}`
  }


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `prospect-crud/?server_type=production&page_size=${action.payload.page_size}&page=${action.payload.page}${request}`, header);

    if (response.status === 200) {
      yield put(GetProspectiveSuccess(response.data))
    } else {
      yield put(GetProspectiveFailure(response.data))
    }

  } catch (err) {
    yield put(GetProspectiveFailure(err))
  }

}

function* GetCountryRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'country/', header);
    console.log("GetCountryRequest",response)

    if (response.status === 200) {
      yield put(GetcountrySuccess(response.data))
    } else {
      yield put(GetcountryFailure(response.data))
    }

  } catch (err) {
    yield put(GetcountryFailure(err.response))
  }

}

function* GetStateRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `states/?country_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetstateSuccess(response.data))
    } else {
      yield put(GetstateFailure(response.data))
    }

  } catch (err) {
    yield put(GetstateFailure(err.response))
  }

}

function* GetcityRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `city/?state_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetcitySuccess(response.data))
    } else {
      yield put(GetcityFailure(response.data))
    }

  } catch (err) {
    yield put(GetcityFailure(err.response))
  }

}

function* GetLocationRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'locations/list/', header);

    if (response.status === 200) {
      yield put(GetLocationSuccess(response.data))
    } else {
      yield put(GetLocationFailure(response.data))
    }

  } catch (err) {

  }

}

function* GetInterestRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'lead-source/list/', header);

    if (response.status === 200) {
      yield put(GetInterestSuccess(response.data))
    } else {
      yield put(GetInterestFailure(response.data))
    }

  } catch (err) {

  }

}


function* AddpropectsRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'prospect-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddpropectsSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/prospects`)
    } else if (response?.data.status === 400) {
      yield put(AddpropectsFailure(response.data));
      Swal.fire({
        icon: 'error',
        text: response?.data.msg,
      });
    } else {
      yield put(AddpropectsFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddpropectsFailure(err.data))

  }


}

function* ViewprospectsRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `prospect-crud/?user_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(ViewprospectsSuccess(response.data))
    } else {
      yield put(ViewprospectsFailure(response.data))
    }

  } catch (err) {

  }

}

function* ExportProspectiveRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `prospect-export/?server_type=production`, action.payload, header);

    if (response.status === 200) {
      yield put(ExportProspectiveSuccess(response.data))
      const link = document.getElementById("Download");
      link.setAttribute("href", response.data?.file_url);
      link.click();
    } else {
      yield put(ExportProspectiveFailure(response.data))
    }

  } catch (err) {
    yield put(ExportProspectiveFailure(err))
  }

}


function* UpdatepropectsRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `prospect-crud/?server_type=production&id=${action.payload.id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdatepropectsSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/prospects`)
    } else {
      yield put(UpdatepropectsFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdatepropectsFailure(err.data))

  }


}

function* DeleteprospectsRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `prospect-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {

      if (response.data.status === 500) {
        yield put(DeleteprospectsFailure(response.data))
        Swal.fire({
          icon: "error",
          text: response.data.msg,
        });
      } else {
        yield put(DeleteprospectsSuccess(response.data))

      }

    } else {
      yield put(DeleteprospectsFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteprospectsFailure(err.data))
  }

}


function* GetEmployeeRequest(action) {
  console.log('GetEmployeeRequest',action)
  var request = ""
  if (action.payload.name !== '' || action.payload.status !== '' || action.payload.employee_type !== '') {
    request = `&name=${action.payload.name}&status=${action.payload.status}&employee_type=${action.payload.employee_type}&employment_type=${action.payload.employment_type}`
  }


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-crud/?server_type=production&page_size=${action.payload.page_size}&page=${action.payload.page}&location_id=${action.payload.location_id}&name=${action.payload.name}&status=${action.payload.status}&employee_type=${action.payload.employee_type}&employment_type=${action.payload.employment_type}`, header);

    if (response.status === 200) {
      yield put(GetEmployeeSuccess(response.data))
    } else {
      yield put(GetEmployeeFailure(response.data))
    }

  } catch (err) {
    yield put(GetEmployeeFailure(err))
  }

}

function* ExportEmployeeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `employee-export/?server_type=production`, action.payload, header);

    if (response.status === 200) {
      yield put(ExportEmployeeSuccess(response.data))
      const link = document.getElementById("Download");
      link.setAttribute("href", response.data?.file_url);
      link.click();
    } else {
      yield put(ExportEmployeeFailure(response.data))
    }

  } catch (err) {
    yield put(ExportEmployeeFailure(err))
  }

}

function* AddemployeeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'employee-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddemployeeSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/employee`)
    }  else if (response?.data?.status === 400) {
      yield put(AddemployeeFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddemployeeFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }

  } catch (err) {
    console.log(err)
    yield put(AddemployeeFailure(err.data))

  }


}

function* ViewEmployeeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-crud/?employee_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(ViewEmployeeSuccess(response.data))
    } else {
      yield put(ViewEmployeeFailure(response.data))
    }

  } catch (err) {
    yield put(ViewEmployeeFailure(err.data))
  }

}

function* UpdateEmployeeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `employee-crud/?server_type=production&id=${action.payload.id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdateEmployeeSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/employee`)
    } else {
      yield put(UpdateEmployeeFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdateEmployeeFailure(err.data))

  }


}

function* DeleteEmployeeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `employee-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteEmployeeSuccess(response.data))

    } else {
      yield put(DeleteEmployeeFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteEmployeeFailure(err.data))
  }

}

function* FetchTitleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee/titile-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchTitleSuccess(response.data))
    } else {
      yield put(FetchTitleFailure(response.data))
    }

  } catch (err) {
    yield put(FetchTitleFailure(err.data))
  }

}

function* GetTitleRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'employee/titile-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetTitleSuccess(response.data))
    } else {
      yield put(GetTitleFailure(response.data))
    }

  } catch (err) {
    yield put(GetTitleFailure(err.data))
  }

}

function* AddTitleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'employee/titile-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddTitleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(AddTitleFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddTitleFailure(err.data))

  }


}

function* UpdateTitleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `employee/titile-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateTitleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(UpdateTitleFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTitleFailure(err.data))
  }

}

function* DeleteTitleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `employee/titile-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteTitleSuccess(response.data))

    } else {
      yield put(DeleteTitleFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteTitleFailure(err.data))
  }

}

function* GetManagerRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'manager-list/', header);

    if (response.status === 200) {
      yield put(GetManagerSuccess(response.data))
    } else {
      yield put(GetManagerFailure(response.data))
    }

  } catch (err) {
    yield put(GetManagerFailure(err.data))
  }

}

function* GetBackgroundListRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'employee/bcc/list/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetBackgroundListSuccess(response.data))
    } else {
      yield put(GetBackgroundListFailure(response.data))
    }

  } catch (err) {
    yield put(GetBackgroundListFailure(err.data))
  }

}

function* GetBackgroundRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'employee/bcc-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetBackgroundSuccess(response.data))
    } else {
      yield put(GetBackgroundFailure(response.data))
    }

  } catch (err) {
    yield put(GetBackgroundFailure(err.data))
  }

}

function* FetchBccRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee/bcc-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchBccSuccess(response.data))
    } else {
      yield put(FetchBccFailure(response.data))
    }

  } catch (err) {
    yield put(FetchBccFailure(err.data))
  }

}

function* AddBackgroundRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'employee/bcc-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddBackgroundSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/settings/BackgroundCheckCompany`)
    } else {
      yield put(AddBackgroundFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddBackgroundFailure(err.data))

  }


}

function* UpdateBccRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `employee/bcc-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateTitleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/settings/BackgroundCheckCompany`)
    } else {
      yield put(UpdateTitleFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTitleFailure(err.data))
  }

}

function* DeleteBccRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `employee/bcc-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteBccSuccess(response.data))

    } else {
      yield put(DeleteBccFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteBccFailure(err.data))
  }

}

function* GetTrainingListRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'company/training-list/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetTrainingListSuccess(response.data))
    } else {
      yield put(GetTrainingListFailure(response.data))
    }

  } catch (err) {
    yield put(GetTrainingListFailure(err.data))
  }

}

function* GetTrainingRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'company/training-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetTrainingSuccess(response.data))
    } else {
      yield put(GetTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(GetTrainingFailure(err.data))
  }

}

function* AddTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    // contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'company/training-crud/?server_type=production', action.payload.data, header);

    if (response.status === 200) {
      yield put(AddTrainingSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/Training/ViewTraining`)
    } else if (response.status === 400) {
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(AddTrainingFailure(err.data))
  }

}

function* FetchTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `company/training-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchTrainingSuccess(response.data))
    } else {
      yield put(FetchTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(FetchTrainingFailure(err.data))
  }

}

function* DeleteTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `company/training-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteTrainingSuccess(response.data))

    } else {
      yield put(DeleteTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteTrainingFailure(err.data))
  }

}

function* UpdateTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `company/training-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.data, header);

    if (response.status === 200) {
      yield put(UpdateTrainingSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/Training/ViewTraining`)
    } else {
      yield put(UpdateTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTrainingFailure(err.data))
  }

}

function* GetGroupRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'group-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetGroupSuccess(response.data))
    } else {
      yield put(GetGroupFailure(response.data))
    }

  } catch (err) {
    yield put(GetGroupFailure(err.data))
  }

}

function* AddGroupRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'group-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddGroupSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/group`)
    } else {
      yield put(AddGroupFailure(response.data))
    }

  } catch (err) {
    yield put(AddGroupFailure(err.data))
  }

}

function* FetchGroupRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `group-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchGroupSuccess(response.data))
    } else {
      yield put(FetchGroupFailure(response.data))
    }

  } catch (err) {
    yield put(FetchGroupFailure(err.data))
  }

}

function* DeleteGroupRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `group-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteGroupSuccess(response.data))

    } else {
      yield put(DeleteGroupFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteGroupFailure(err.data))
  }

}

function* UpdateGroupRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `group-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateGroupSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/group`)
    } else {
      yield put(UpdateGroupFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateGroupFailure(err.data))
  }

}

function* GetGradeRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'grade-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetGradeSuccess(response.data))
    } else {
      yield put(GetGradeFailure(response.data))
    }

  } catch (err) {
    yield put(GetGradeFailure(err.data))
  }

}

function* AddGradeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'grade-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddGradeSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/grade`)
    } else {
      yield put(AddGradeFailure(response.data))
    }

  } catch (err) {
    yield put(AddGradeFailure(err.data))
  }

}

function* FetchGradeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `grade-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchGradeSuccess(response.data))
    } else {
      yield put(FetchGradeFailure(response.data))
    }

  } catch (err) {
    yield put(FetchGradeFailure(err.data))
  }

}

function* UpdateGradeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `grade-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateGradeSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/grade`)
    } else {
      yield put(UpdateGradeFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateGradeFailure(err.data))
  }

}

function* DeleteGradeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `grade-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteGradeSuccess(response.data))

    } else {
      yield put(DeleteGradeFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteGradeFailure(err.data))
  }

}

function* GetSubjectRequest(action) {

  var request = ""
  if (action.payload.name !== '' || action.payload.group_id !== '') {
    request = `&name=${action.payload.name}&group_id=${action.payload.group_id}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `subjects-crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GetSubjectSuccess(response.data))
    } else {
      yield put(GetSubjectFailure(response.data))
    }

  } catch (err) {
    yield put(GetSubjectFailure(err.data))
  }

}

function* GetAllGroupListRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'group-list/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetAllGroupListSuccess(response.data))
    } else {
      yield put(GetAllGroupListFailure(response.data))
    }

  } catch (err) {
    yield put(GetAllGroupListFailure(err.data))
  }

}

function* AddSubjectRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'subjects-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddSubjectSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/subject`)
    } else {
      yield put(AddSubjectFailure(response.data))
    }

  } catch (err) {
    yield put(AddSubjectFailure(err.data))
  }

}

function* FetchSubjectRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `subjects-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchSubjectSuccess(response?.data?.results[0]))
    } else {
      yield put(FetchSubjectFailure(response.data))
    }

  } catch (err) {
    yield put(FetchSubjectFailure(err.data))
  }

}

function* UpdateSubjectRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `subjects-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateSubjectSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/subject`)
    } else {
      yield put(UpdateSubjectFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateSubjectFailure(err.data))
  }

}

function* DeleteSubjectRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `subjects-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteSubjectSuccess(response.data))

    } else {
      yield put(DeleteSubjectFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteSubjectFailure(err.data))
  }

}


function* GetSyllabusRequest(action) {



  var request = ""
  if (action.payload.name !== '' || action.payload.grade_id !== '' || action.payload.group_id !== '' || action.payload.subject_id !== '' || action.payload.module_id !== '' || action.payload.topic_id !== '') {
    request = `&name=${action.payload.name}&grade_id=${action.payload.grade_id}&group_id=${action.payload.group_id}&subject_id=${action.payload.subject_id}&module_id=${action.payload.module_id}&topic_id=${action.payload.topic_id}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `syllabus-crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GetSyllabusSuccess(response.data))
    } else {
      yield put(GetSyllabusFailure(response.data))
    }

  } catch (err) {
    yield put(GetSyllabusFailure(err.data))
  }

}

function* AddSyllabusRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'syllabus-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddSyllabusSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/syllabus`)
    } else {
      yield put(AddSyllabusFailure(response.data))
    }

  } catch (err) {
    yield put(AddSyllabusFailure(err.data))
  }

}

function* FetchSyllabusRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `syllabus-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchSyllabusSuccess(response.data))
    } else {
      yield put(FetchSyllabusFailure(response.data))
    }

  } catch (err) {
    yield put(FetchSyllabusFailure(err.data))
  }

}

function* GetModuleRequest(action) {

  var request = ""
  if (action.payload.name !== '' || action.payload.group_id !== '' || action.payload.syllabus_id !== '') {
    request = `&name=${action.payload.name}&group_id=${action.payload.group_id}&syllabus_id=${action.payload.syllabus_id}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `modules-crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GetModuleSuccess(response.data))
    } else {
      yield put(GetModuleFailure(response.data))
    }

  } catch (err) {
    yield put(GetModuleFailure(err.data))
  }

}

function* AddModuleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'modules-crud/?server_type=production', action.payload, header);

    if (response.status === 200) {
      yield put(AddModuleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddModuleFailure(response.data))
    }

  } catch (err) {
    yield put(AddModuleFailure(err.data))
  }

}

function* GetTypeRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'modules/type/list/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetTypeSuccess(response.data))
    } else {
      yield put(GetTypeFailure(response.data))
    }

  } catch (err) {
    yield put(GetTypeFailure(err.data))
  }

}

function* UpdateSyllabusRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `syllabus-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateSyllabusSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/curriculum/syllabus`)
    } else {
      yield put(UpdateSyllabusFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateSyllabusFailure(err.data))
  }

}

function* FetchModuleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `modules-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(FetchModuleSuccess(response.data))
    } else {
      yield put(FetchModuleFailure(response.data))
    }

  } catch (err) {
    yield put(FetchModuleFailure(err.data))
  }

}

function* UpdateModuleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `modules-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateModuleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(UpdateModuleFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateModuleFailure(err.data))
  }

}

function* DeleteModuleRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `modules-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteModuleSuccess(response.data))

    } else {
      yield put(DeleteModuleFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteModuleFailure(err.data))
  }

}

function* DeleteSyllabusRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `syllabus-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteSyllabusSuccess(response.data))

    } else {
      yield put(DeleteSyllabusFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteSyllabusFailure(err.data))
  }

}

function* GetTopicRequest(action) {

  var request = ""
  if (action.payload.name !== '' || action.payload.group_id !== '' || action.payload.syllabus_id !== '' || action.payload.module_id !== '') {
    request = `&name=${action.payload.name}&group_id=${action.payload.group_id}&syllabus_id=${action.payload.syllabus_id}&module_id=${action.payload.module_id}`
  }


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `topic-crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GetTopicSuccess(response.data))
    } else {
      yield put(GetTopicFailure(response.data))
    }

  } catch (err) {
    yield put(GetTopicFailure(err.data))
  }

}

function* AddTopicRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'topic-crud/?server_type=production', action.payload, header);

    if (response.status === 200) {
      yield put(AddTopicSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddTopicFailure(response.data))
    }

  } catch (err) {
    yield put(AddTopicFailure(err.data))
  }

}

function* FetchTopicRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `topic-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(FetchTopicSuccess(response.data))
    } else {
      yield put(FetchTopicFailure(response.data))
    }

  } catch (err) {
    yield put(FetchTopicFailure(err.data))
  }

}

function* DeleteTopicRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `topic-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteTopicSuccess(response.data))

    } else {
      yield put(DeleteTopicFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteTopicFailure(err.data))
  }

}

function* UpdateTopicRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `topic-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateTopicSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(UpdateTopicFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTopicFailure(err.data))
  }

}

function* AddguardianRequest(action) {
  console.log('AddguardianRequest',action)
  const header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token,
  };

  try {
    const response = yield call(postApi, 'guardian-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data);

    if (response?.data?.status === 200) {
      yield put(AddguardianSuccess(response.data));
      Swal.fire({
        icon: 'success',
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/prospects/view/${action.payload.obj.prospect_id}/${action.payload.user_id}`);
    } else if (response?.data.status === 400) {
      yield put(AddguardianFailure(response.data));
      Swal.fire({
        icon: 'error',
        text: response?.data.msg,
      });
    } else {
      yield put(AddguardianFailure(response.data));
      Swal.fire({
        icon: 'error',
        text: response.data?.message,
      });
    }
  } catch (err) {
    console.log(err);
    yield put(AddguardianFailure(err.data));
  }
}

function* GetGuardianRequest(action) {

  console.log(action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `guardian-crud/?server_type=production${action.payload.id != '' ? `&prospect_id=${action.payload.id}` : ''}`, header);

    if (response.status === 200) {
      yield put(GetGuardianSuccess(response.data))
    } else {
      yield put(GetGuardianFailure(response.data))
    }

  } catch (err) {
    yield put(GetGuardianFailure(err.data))
  }

}

function* ViewguardianRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    if (action.payload.id) {
      let response = yield call(getApi, `guardian-crud/?server_type=production&id=${action.payload.id}`, header);
      if (response.status === 200) {
        yield put(ViewguardianSuccess(response.data))
      } else {
        yield put(ViewguardianFailure(response.data))
      }

    } else {
      let response = yield call(getApi, `guardian-crud/?server_type=production`, header);
      if (response.status === 200) {
        yield put(ViewguardianSuccess(response.data))
      } else {
        yield put(ViewguardianFailure(response.data))
      }

    }



  } catch (err) {

  }

}

function* ExportguardianRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `guardian-crud/?server_type=production&is_export=1`, action.payload, header);

    if (response.status === 200) {
      yield put(ExportguardianSuccess(response.data))
      const link = document.getElementById("Download");
      link.setAttribute("href", response.data?.file_url);
      link.click();
    } else {
      yield put(ExportguardianFailure(response.data))
    }

  } catch (err) {
    yield put(ExportguardianFailure(err))
  }

}


function* UpdateguardianRequest(action) {
  console.log(action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `guardian-crud/?server_type=production&id=${action.payload.id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdateguardianSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/prospects/view/${action.payload.obj.prospect_id}/${action.payload.id}`)
    } else {
      yield put(UpdateguardianFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdateguardianFailure(err.data))

  }


}

function* DeleteguardianRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `guardian-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteguardianSuccess(response.data))

    } else {
      yield put(DeleteguardianFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteguardianFailure(err.data))
  }

}

function* AddlocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'locations-crud/?server_type=production', action.payload.obj, header);

    console.log(response.status)

    if (response?.status === 200) {
      yield put(AddlocationSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/location`)
    } else {
      yield put(AddlocationFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    Swal.fire({
      icon: "error",
      text: err?.response?.data?.detail,
    });
    console.log(err)
    yield put(AddlocationFailure(err.data))

  }


}

function* ViewlocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    if (action.payload.id) {
      let response = yield call(getApi, `locations-crud/?prospect_id=${action.payload.id}`, header);
      if (response.status === 200) {
        yield put(ViewlocationSuccess(response.data))
      } else {
        yield put(ViewlocationFailure(response.data))
      }

    } else {
      let response = yield call(getApi, `locations-crud/?`, header);
      if (response.status === 200) {
        yield put(ViewlocationSuccess(response.data))
      } else {
        yield put(ViewlocationFailure(response.data))
      }

    }



  } catch (err) {

  }

}

function* GetCrudlocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `locations-crud/?server_type=production`, header);

    if (response.status === 200) {
      yield put(GetCrudlocationSuccess(response.data))
    } else {
      yield put(GetCrudlocationFailure(response.data))
    }




  } catch (err) {
    yield put(GetCrudlocationFailure(err.data))
  }

}

function* FetchlocationRequest(action) {
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `locations-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchlocationSuccess(response.data))
    } else {
      yield put(FetchlocationFailure(response.data))
    }


  } catch (err) {
    yield put(FetchlocationFailure(err.data))
  }

}

function* ExportlocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `locations-crud/?server_type=production&is_export=1`, action.payload, header);

    if (response.status === 200) {
      yield put(ExportlocationSuccess(response.data))
      const link = document.getElementById("Download");
      link.setAttribute("href", response.data?.file_url);
      link.click();
    } else {
      yield put(ExportlocationFailure(response.data))
    }

  } catch (err) {
    yield put(ExportlocationFailure(err))
  }

}


function* UpdatelocationRequest(action) {
  console.log("UpdatelocationRequest", action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `locations-crud/?server_type=production&id=${action.payload.id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdatelocationSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/location`)
    } else {
      yield put(UpdatelocationFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdatelocationFailure(err.data))

  }


}

function* GetlocationsRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'locations-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetlocationsSuccess(response.data))
    } else {
      yield put(GetlocationsFailure(response.data))
    }

  } catch (err) {
    yield put(GetlocationsFailure(err.data))
  }

}

function* DeletelocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `locations-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeletelocationSuccess(response.data))

    } else {
      yield put(DeletelocationFailure(response.data))
    }

  } catch (err) {
    yield put(DeletelocationFailure(err.data))
  }

}

function* MakePrimaryRequest(action) {
  console.log("MakePrimaryRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `change/guardian-prospect/?server_type=production&id=${action.payload.id}`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      console.log("MakePrimaryRequest",response?.data?.prospect_id)
      yield put(MakePrimarySuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/prospects/view/${response?.data?.prospect_id}/${action.payload.userid}`);
      window.location.reload();
    } else {
      yield put(MakePrimaryFailure(response.data))

    }


  } catch (err) {
    console.log(err)
    yield put(MakePrimaryFailure(err.data))

  }


}

function* GetEmployeelocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-locations-crud/?server_type=production&employee_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetEmployeelocationSuccess(response.data))
    } else {
      yield put(GetEmployeelocationFailure(response.data))
    }
  } catch (err) {
    yield put(GetEmployeelocationFailure(err.data))
  }

}

function* AddEmployeelocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'employee-locations-crud/?server_type=production', action.payload, header);

    console.log(response.status)

    if (response?.status === 200) {
      yield put(AddEmployeelocationSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddEmployeelocationFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    Swal.fire({
      icon: "error",
      text: err?.response?.data?.detail,
    });
    console.log(err)
    yield put(AddEmployeelocationFailure(err.data))

  }


}

function* FetchEmployeelocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-locations-crud/?server_type=production&employee_id=${action.payload.employee_id}&id=${action.payload.rowId}`, header);

    if (response.status === 200) {
      yield put(FetchEmployeelocationSuccess(response.data))
    } else {
      yield put(FetchEmployeelocationFailure(response.data))
    }

  } catch (err) {
    yield put(FetchEmployeelocationFailure(err.data))
  }

}

function* DeleteEmployeelocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `employee-locations-crud/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}`, header);

    if (response.status === 200) {
      yield put(DeleteEmployeelocationSuccess(response.data))

    } else {
      yield put(DeleteEmployeelocationFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteEmployeelocationFailure(err.data))
  }

}

function* UpdateEmployeelocationRequest(action) {
  console.log(action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `employee-locations-crud/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdateEmployeelocationSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(UpdateEmployeelocationFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdateEmployeelocationFailure(err.data))

  }


}

function* MakeLocationPrimaryRequest(action) {
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `employee/locations/status/update/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(MakeLocationPrimarySuccess(response.data))

    } else {
      yield put(MakeLocationPrimaryFailure(response.data))

    }


  } catch (err) {
    console.log(err)
    yield put(MakeLocationPrimaryFailure(err.data))

  }


}

function* GetAssignEmployeelocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `locations/list/assign/employee/?server_type=production&employee_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetAssignEmployeelocationSuccess(response.data))
    } else {
      yield put(GetAssignEmployeelocationFailure(response.data))
    }
  } catch (err) {
    yield put(GetAssignEmployeelocationFailure(err.data))
  }

}


function* AddstudentRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'student-crud/?server_type=production', action.payload.obj, header);

    console.log(response.status)

    if (response?.status === 200) {
      yield put(AddstudentSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/prospects/view/${action.payload.obj.prospect_id}/${action.payload.user_id}`)
    } else {
      yield put(AddstudentFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    Swal.fire({
      icon: "error",
      text: err?.response?.data?.detail,
    });
    console.log(err)
    yield put(AddstudentFailure(err.data))

  }


}

function* ViewstudentRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    if (action.payload.id) {
      let response = yield call(getApi, `student-crud/?server_type=production&id=${action.payload.id}`, header);
      if (response.status === 200) {
        yield put(ViewstudentSuccess(response.data))
      } else {
        yield put(ViewstudentFailure(response.data))
      }

    } else {
      let response = yield call(getApi, `student-crud/?server_type=production`, header);
      if (response.status === 200) {
        yield put(ViewstudentSuccess(response.data))
      } else {
        yield put(ViewstudentFailure(response.data))
      }

    }



  } catch (err) {

  }

}

function* FetchstudentRequest(action) {
  console.log("FetchstudentRequest", action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchstudentSuccess(response.data))
    } else {
      yield put(FetchstudentFailure(response.data))
    }


  } catch (err) {
    yield put(FetchstudentFailure(err.data))
  }

}

function* ExportstudentRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `student-crud/?server_type=production&is_export=1`, action.payload, header);

    if (response.status === 200) {
      yield put(ExportstudentSuccess(response.data))
      const link = document.getElementById("Download");
      link.setAttribute("href", response.data?.file_url);
      link.click();
    } else {
      yield put(ExportstudentFailure(response.data))
    }

  } catch (err) {
    yield put(ExportstudentFailure(err))
  }

}


function* UpdatestudentRequest(action) {
  console.log("UpdatestudentRequest", action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `student-crud/?server_type=production&id=${action.payload.id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdatestudentSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/students`)
      // action.payload.navigate(`/${userinfo?.company_slug}/prospects/view/${action.payload.obj.prospect_id}`)
    } else {
      yield put(UpdatestudentFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdatestudentFailure(err.data))

  }


}

function* GetstudentRequest(action) {
  console.log("GetstudentRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-crud/?server_type=production&test_assign=single_student&test_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetstudentSuccess(response.data))
    } else {
      yield put(GetstudentFailure(response.data))
    }

  } catch (err) {
    yield put(GetstudentFailure(err.data))
  }

}

function* GetStudentListRequest(action) {
  console.log("GetStudentListRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'student-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetStudentListSuccess(response.data))
    } else {
      yield put(GetStudentListFailure(response.data))
    }

  } catch (err) {
    yield put(GetStudentListFailure(err.data))
  }

}

function* DeletestudentRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `student-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeletestudentSuccess(response.data))

    } else {
      yield put(DeletestudentFailure(response.data))
    }

  } catch (err) {
    yield put(DeletestudentFailure(err.data))
  }

}


function* GetDefaultlocationsRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `user-locations-list/?server_type=production&user_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetDefaultlocationsSuccess(response.data))
    } else {
      yield put(GetDefaultlocationsFailure(response.data))
    }

  } catch (err) {
    yield put(GetDefaultlocationsFailure(err.data))
  }

}

function* GetModuleTypeRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'modules/type/list/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetModuleTypeSuccess(response.data))
    } else {
      yield put(GetModuleTypeFailure(response.data))
    }

  } catch (err) {
    yield put(GetModuleTypeFailure(err.data))
  }

}

function* AddModuleTypeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'modules/type/add/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddModuleTypeSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/settings/Module_Type`)
    } else {
      yield put(AddModuleTypeFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddModuleTypeFailure(err.data))

  }


}


function* DeleteModuleTypeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `modules/type/list/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteModuleTypeSuccess(response.data))

    } else {
      yield put(DeleteModuleTypeFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteModuleTypeFailure(err.data))
  }

}

function* AddtestRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'test-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddtestSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/Test/Viewassessment`)
    } else {
      yield put(AddtestFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddtestFailure(err.data))

  }


}

function* UpdatetestRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `test-crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.obj, header);
    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdatetestSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/Test/Viewassessment`)
    } else {
      yield put(UpdatetestFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdatetestFailure(err.data))

  }


}

function* DeletetestRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `test-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeletetestSuccess(response.data))

    } else {
      yield put(DeletetestFailure(response.data))
    }

  } catch (err) {
    yield put(DeletetestFailure(err.data))
  }

}

function* GettestRequest() {


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'test-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GettestSuccess(response.data))
    } else {
      yield put(GettestFailure(response.data))
    }

  } catch (err) {
    yield put(GettestFailure(err.data))
  }

}

function* GettestfilterRequest(action) {
  console.log("GettestfilterRequest", action)

  var request = ""
  if (action.payload.name !== '' || action.payload.grade_id !== '' || action.payload.subject_id !== '' || action.payload.test_type !== '') {
    request = `&name=${action.payload.name}&grade_id=${action.payload.grade_id}&subject_id=${action.payload.subject_id}&test_type=${action.payload.test_type}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GettestfilterSuccess(response.data))
    } else {
      yield put(GettestfilterFailure(response.data))
    }

  } catch (err) {
    yield put(GettestfilterFailure(err.data))
  }

}

function* FetchtestRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GettestSuccess(response.data))
    } else {
      yield put(GettestFailure(response.data))
    }

  } catch (err) {
    yield put(GettestFailure(err.data))
  }

}

function* AddquesRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };
  try {
    let response = yield call(postApi, `test-question-crud/?server_type=production&test_id=${action.payload.id}`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddtestSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/Test/Assessmentdetails/${action.payload.id}`)
    } else {
      yield put(AddtestFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddtestFailure(err.data))

  }


}

function* GetquesRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-question-crud/?server_type=production&test_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetquesSuccess(response.data))
    } else {
      yield put(GetquesFailure(response.data))
    }

  } catch (err) {
    yield put(GetquesFailure(err.data))
  }

}

function* ViewQuestionRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-question-crud/?server_type=production&test_id=${action.payload.test_id}&id=${action.payload.id}`, header);
    if (response.status === 200) {
      yield put(ViewQuestionSuccess(response.data))
    } else {
      yield put(ViewQuestionFailure(response.data))
    }

  } catch (err) {
    yield put(ViewQuestionFailure(err))
  }

}

function* UpdateQuestionRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `test-question-crud/?server_type=production&test_id=${action.payload.test_id}&id=${action.payload.id}&method=edit`, action.payload.obj, header);
    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdateQuestionSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/Test/Assessmentdetails/${action.payload.test_id}`)
    } else {
      yield put(UpdateQuestionFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdateQuestionFailure(err.data))

  }


}

function* DeleteQuestionRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `test-question-crud/?server_type=production&test_id=${action.payload.test_id}&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(DeleteQuestionSuccess(response.data))

    } else {
      yield put(DeleteQuestionFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteQuestionFailure(err.data))
  }

}


//////////////////////////

function* GetTeacherTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-training-crud/?server_type=production&employee_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetTeacherTrainingSuccess(response.data))
    } else {
      yield put(GetTeacherTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(GetTeacherTrainingFailure(err.data))
  }

}

function* FetchTeacherTrainingRequest(action) {
  console.log(action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-training-crud/?server_type=production&id=${action.payload.rowId}`, header);

    if (response.status === 200) {
      yield put(FetchTeacherTrainingSuccess(response.data))
    } else {
      yield put(FetchTeacherTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(FetchTeacherTrainingFailure(err.data))
  }

}

function* AddTeacherTrainingRequest(action) {
  console.log(action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'employee-training-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddTeacherTrainingSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/employee/view/${action.payload.obj.employee_id}`)
    } else {
      yield put(AddTeacherTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(AddTeacherTrainingFailure(err.data))
  }

}

function* DeleteTeacherTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `employee-training-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteTeacherTrainingSuccess(response.data))

    } else {
      yield put(DeleteTeacherTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteTeacherTrainingFailure(err.data))
  }

}

function* UpdateTeacherTrainingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `employee-training-crud/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}&method=edit`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateTeacherTrainingSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/employee/view/${action.payload.obj.employee_id}`)
    } else {
      yield put(UpdateTeacherTrainingFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTeacherTrainingFailure(err.data))
  }

}

function* GetSubjectChangeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `subjects-crud/?server_type=production&group_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetSubjectChangeSuccess(response.data))
    } else {
      yield put(GetSubjectChangeFailure(response.data))
    }

  } catch (err) {
    yield put(GetSubjectChangeFailure(err.response))
  }

}

// users/profile_api/
function* GetProfileRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `users/profile_api/`, header);

    if (response.status === 200) {
      yield put(GetProfileSuccess(response.data))
    } else {
      yield put(GetProfileFailure(response.data))
    }

  } catch (err) {
    yield put(GetProfileFailure(err.response))
  }

}


function* UpdateProfileRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `users/profile_apiss/?method=edit`, action.payload.formData, header);

    if (response.status === 200) {
      yield put(UpdateProfileSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/settings/MyProfile`)
    } else {
      yield put(UpdateProfileFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateProfileFailure(err.data))
  }

}

function* GetTopicChangeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `topic-crud/?server_type=production&module_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetTopicChangeSuccess(response.data))
    } else {
      yield put(GetTopicChangeFailure(response.data))
    }

  } catch (err) {
    yield put(GetTopicChangeFailure(err.response))
  }

}

function* GetModuleChangeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `modules-crud/?server_type=production&group_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetModuleChangeSuccess(response.data))
    } else {
      yield put(GetModuleChangeFailure(response.data))
    }

  } catch (err) {
    yield put(GetModuleChangeFailure(err.response))
  }

}

function* GetSyllabusChangeRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `syllabus-crud/?server_type=production&subject_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetSyllabusChangeSuccess(response.data))
    } else {
      yield put(GetSyllabusChangeFailure(response.data))
    }

  } catch (err) {
    yield put(GetSyllabusChangeFailure(err.response))
  }

}

function* UpdateorderListRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `test-question-order/?server_type=production&test_id=${action.payload.test_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateorderListSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(UpdateorderListFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateorderListFailure(err.data))
  }

}

function* FetchQuestionRequest(action) {
  console.log(action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-question-crud/?server_type=production&test_id=${action.payload.test_id}&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchQuestionSuccess(response.data))
    } else {
      yield put(FetchQuestionFailure(response.data))
    }

  } catch (err) {
    yield put(FetchQuestionFailure(err.data))
  }

}

function* GetSyllabusTopicRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `topic-crud/?server_type=production&module_id=${action.payload.module_id}`, header);

    if (response.status === 200) {
      yield put(GetSyllabusTopicSuccess(response.data))
    } else {
      yield put(GetSyllabusTopicFailure(response.data))
    }

  } catch (err) {
    yield put(GetSyllabusTopicFailure(err.response))
  }

}

function* DownloadQuestionRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-question-pdf-generate/?server_type=production&test_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DownloadQuestionSuccess(response.data))
      const link = document.getElementById("DownloadQuestion");
      link.setAttribute("href", response.data?.pdf_download_link);
      link.click();
    } else {
      yield put(DownloadQuestionFailure(response.data))
    }

  } catch (err) {
    yield put(DownloadQuestionFailure(err))
  }

}

function* AssignScheduleRequest(action) {
  console.log("AssignScheduleRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `schedule-test/?server_type=production&test_id=${action.payload.TestId}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(AssignScheduleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // window.location.reload();
      // action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.student_id}`)
    } else {
      yield put(AssignScheduleFailure(response.data))
    }

  } catch (err) {
    yield put(AssignScheduleFailure(err.data))
  }

}

function* AssignGroupScheduleRequest(action) {
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `schedule-test/?server_type=production&test_id=${action.payload.TestId}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(AssignGroupScheduleSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/employee/view/${action.payload.obj.employee_id}`)
    } else {
      yield put(AssignGroupScheduleFailure(response.data))
    }

  } catch (err) {
    yield put(AssignGroupScheduleFailure(err.data))
  }

}

function* GetGradeStudentRequest(action) {
  console.log('groupStudent', action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-crud/?server_type=production&grade_id=${action.payload.grade_id}&test_id=${action.payload.tid}`, header);

    if (response.status === 200) {
      yield put(GetGradeStudentSuccess(response.data))
    } else {
      yield put(GetGradeStudentFailure(response.data))
    }

  } catch (err) {
    yield put(GetGradeStudentFailure(err.response))
  }

}

function* FetchTeacherActivityRequest(action) {
  console.log("Qwertyuioiuytr FetchTeacherActivityRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `activities_crud/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}`, header);

    if (response.status === 200) {
      yield put(FetchTeacherActivitySuccess(response.data))
    } else {
      yield put(FetchTeacherActivityFailure(response.data))
    }

  } catch (err) {
    yield put(FetchTeacherActivityFailure(err.data))
  }

}

function* GetTeacherActivityRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `activities_crud/?server_type=production&teacher_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetTeacherActivitySuccess(response.data))
    } else {
      yield put(GetTeacherActivityFailure(response.data))
    }

  } catch (err) {
    yield put(GetTeacherActivityFailure(err.data))
  }

}

function* AddTeacherActivityRequest(action) {
  console.log('AddTeacherActivityRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'activities_crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddTeacherActivitySuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/employee/view/${action.payload.obj.employee_id}`)
    } else {
      yield put(AddTeacherActivityFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddTeacherActivityFailure(err.data))

  }


}

function* UpdateTeacherActivityRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `activities_crud/?server_type=production&method=edit&id=${action.payload.rowId}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateTeacherActivitySuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(UpdateTeacherActivityFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTeacherActivityFailure(err.data))
  }

}

function* DeleteTeacherActivityRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `activities_crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteTeacherActivitySuccess(response.data))

    } else {
      yield put(DeleteTeacherActivityFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteTeacherActivityFailure(err.data))
  }

}

function* GetDocumentRequest(action) {

  var request = ""
  if (action.payload.employee_id !== '' || action.payload.student_id !== '') {
    request = `&employee_id=${action.payload.employee_id}&student_id=${action.payload.student_id}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `document_crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GetDocumentSuccess(response.data))
    } else {
      yield put(GetDocumentFailure(response.data))
    }

  } catch (err) {
    yield put(GetDocumentFailure(err.data))
  }

}

function* AddDocumentRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    // contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'document_crud/?server_type=production', action.payload.data, header);

    if (response.status === 200) {
      yield put(AddDocumentSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/Training/ViewTraining`)
    } else {
      yield put(AddDocumentFailure(response.data))
    }

  } catch (err) {
    yield put(AddDocumentFailure(err.data))
  }

}

function* FetchDocumentRequest(action) {
  console.log("Qwertyuioiuytr FetchDocumentRequest", action)


  var request = ""
  if (action.payload.employee_id !== '' || action.payload.student_id !== '') {
    request = `&employee_id=${action.payload.obj?.employee_id}&student_id=${action.payload.obj?.student_id}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `document_crud/?server_type=production&id=${action.payload.rowId}${request}`, header);

    if (response.status === 200) {
      yield put(FetchDocumentSuccess(response.data))
    } else {
      yield put(FetchDocumentFailure(response.data))
    }

  } catch (err) {
    yield put(FetchDocumentFailure(err.data))
  }

}

function* DeleteDocumentRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `document_crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteDocumentSuccess(response.data))

    } else {
      yield put(DeleteDocumentFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteDocumentFailure(err.data))
  }

}

function* UpdateDocumentRequest(action) {
  console.log('UpdateDocumentRequest', action)

  let header = {
    Accept: 'application/json',
    contenttype: 'multipart/form-data',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `document_crud/?server_type=production&method=edit&id=${action.payload.id}`, action.payload.data, header);

    if (response.status === 200) {
      yield put(UpdateDocumentSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/Training/ViewTraining`)
    } else {
      yield put(UpdateDocumentFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateDocumentFailure(err.data))
  }

}

function* GetattendanceRequest(action) {

  var request = ""
  if (action.payload.employee_id !== '' || action.payload.student_id !== '') {
    request = `&employee_id=${action.payload.employee_id}&student_id=${action.payload.student_id}`
  }

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `attendance-crud/?server_type=production${request}`, header);

    if (response.status === 200) {
      yield put(GetattendanceSuccess(response.data))
    } else {
      yield put(GetattendanceFailure(response.data))
    }

  } catch (err) {
    yield put(GetattendanceFailure(err.data))
  }

}

function* AddattendanceRequest(action) {
  console.log("AddattendanceRequest", action)
  let header = {
    Accept: 'application/json',
    // contenttype: 'multipart/form-data',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `attendance-crud/?server_type=production&student_id=${action.payload.student_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddattendanceSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });

      action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.student_id}`)
    } else {
      yield put(AddattendanceFailure(response.data))
    }

  } catch (err) {
    yield put(AddattendanceFailure(err.data))
  }

}

function* FetchattendanceRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-attendance-time_details/?server_type=production&student_id=${action.payload.id}&id=${action.payload.rowId}&attendance_date=${action.payload.did}`, header);

    if (response.status === 200) {
      yield put(FetchattendanceSuccess(response.data))
    } else {
      yield put(FetchattendanceFailure(response.data))
    }

  } catch (err) {
    yield put(FetchattendanceFailure(err.data))
  }

}

function* DeleteattendanceRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `attendance-crud/?server_type=production&student_id=${action.payload.student_id}&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(DeleteattendanceSuccess(response.data))

    } else {
      yield put(DeleteattendanceFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteattendanceFailure(err.data))
  }

}

function* UpdateattendanceRequest(action) {
  console.log('UpdateattendanceRequest', action)

  let header = {
    Accept: 'application/json',
    // contenttype: 'multipart/form-data',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `attendance-crud/?server_type=production&method=edit&student_id=${action.payload.student_id}&id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateattendanceSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/Training/ViewTraining`)
    } else {
      yield put(UpdateattendanceFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateattendanceFailure(err.data))
  }

}



function* GetEmployeevirtuallocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-virtual-locations-crud/?server_type=production&employee_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetEmployeevirtuallocationSuccess(response.data))
    } else {
      yield put(GetEmployeevirtuallocationFailure(response.data))
    }
  } catch (err) {
    yield put(GetEmployeevirtuallocationFailure(err.data))
  }

}

function* AddEmployeevirtuallocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'employee-virtual-locations-crud/?server_type=production', action.payload, header);

    console.log(response.status)

    if (response?.status === 200) {
      yield put(AddEmployeevirtuallocationSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddEmployeevirtuallocationFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    Swal.fire({
      icon: "error",
      text: err?.response?.data?.detail,
    });
    console.log(err)
    yield put(AddEmployeevirtuallocationFailure(err.data))

  }


}

function* FetchEmployeevirtuallocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `employee-virtual-locations-crud/?server_type=production&employee_id=${action.payload.employee_id}&id=${action.payload.rowId}`, header);

    if (response.status === 200) {
      yield put(FetchEmployeevirtuallocationSuccess(response.data))
    } else {
      yield put(FetchEmployeevirtuallocationFailure(response.data))
    }

  } catch (err) {
    yield put(FetchEmployeevirtuallocationFailure(err.data))
  }

}

function* DeleteEmployeevirtuallocationRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `employee-virtual-locations-crud/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}`, header);

    if (response.status === 200) {
      yield put(DeleteEmployeevirtuallocationSuccess(response.data))

    } else {
      yield put(DeleteEmployeevirtuallocationFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteEmployeevirtuallocationFailure(err.data))
  }

}

function* UpdateEmployeevirtuallocationRequest(action) {
  console.log(action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(putApi, `employee-virtual-locations-crud/?server_type=production&id=${action.payload.rowId}&employee_id=${action.payload.employee_id}&method=edit`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(UpdateEmployeevirtuallocationSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(UpdateEmployeevirtuallocationFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(UpdateEmployeevirtuallocationFailure(err.data))

  }


}


function* GetExistingQuestionRequest(action) {


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `quiestion-list-without-existing-id/?server_type=production&test_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetExistingSuccess(response.data))
    } else {
      yield put(GetExistingFailure(response.data))
    }

  } catch (err) {
    yield put(GetExistingFailure(err.response))
  }

}

function* GetExistingTestRequest(action) {


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-list-without-existing-id/?server_type=production&test_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetExistingTestSuccess(response.data))
    } else {
      yield put(GetExistingTestFailure(response.data))
    }

  } catch (err) {
    yield put(GetExistingTestFailure(err.response))
  }

}

function* AddExistingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, `existing-test-question-add/?server_type=production&test_id=${action.payload.test_id}`, action.payload.obj, header);

    if (response?.status === 200) {
      yield put(AddExistingSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddExistingFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }

  } catch (err) {

    console.log(err)
    yield put(AddExistingFailure(err.data))

  }


}


function* GetAttendenceRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `attendance-crud/?server_type/?server_type=production&employee_user_id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(GetAttendenceSuccess(response.data))
    } else {
      yield put(GetAttendenceFailure(response.data))
    }
  } catch (err) {
    yield put(GetAttendenceFailure(err.data))
  }

}


function* AddpunchRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, `attendance-crud/?server_type/?server_type=production&employee_id=${action.payload.employee_id}`, action.payload.obj, header);

    if (response?.status === 200) {
      yield put(AddpunchSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(AddpunchFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }

  } catch (err) {

    console.log(err)
    yield put(AddpunchFailure(err.data))

  }


}

function* FetchTeacherRequest(action) {
  console.log('TeacherRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-teacher-crud/?server_type=production&id=${action.payload.rowId}&school_id=${action.payload.school_id}&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(FetchTeacherSuccess(response.data))
    } else {
      yield put(FetchTeacherFailure(response.data))
    }

  } catch (err) {
    yield put(FetchTeacherFailure(err.data))
  }

}

function* GetTeacherRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'student-teacher-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetTeacherSuccess(response.data))
    } else {
      yield put(GetTeacherFailure(response.data))
    }

  } catch (err) {
    yield put(GetTeacherFailure(err.data))
  }

}

function* AddTeacherRequest(action) {
  console.log('AddTeacherRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, `student-teacher-crud/?server_type=production&school_id=${action.payload.school_id}&student_id=${action.payload.student_id}`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddTeacherSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/employee/view/${action.payload.obj.employee_id}`)
    } else {
      yield put(AddTeacherFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddTeacherFailure(err.data))

  }


}

function* UpdateTeacherRequest(action) {
  console.log("UpdateTeacherRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `student-teacher-crud/?server_type=production&method=edit&id=${action.payload.rowId}&school_id=${action.payload.school_id}&student_id=${action.payload.student_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateTeacherSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(UpdateTeacherFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateTeacherFailure(err.data))
  }

}

function* DeleteTeacherRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `student-teacher-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteTeacherSuccess(response.data))

    } else {
      yield put(DeleteTeacherFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteTeacherFailure(err.data))
  }

}

function* FetchSchoolRequest(action) {
  console.log('FetchSchoolRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-school-crud/?server_type=production&id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(FetchSchoolSuccess(response.data))
    } else {
      yield put(FetchSchoolFailure(response.data))
    }

  } catch (err) {
    yield put(FetchSchoolFailure(err.data))
  }

}

function* GetSchoolRequest() {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, 'student-school-crud/?server_type=production', header);

    if (response.status === 200) {
      yield put(GetSchoolSuccess(response.data))
    } else {
      yield put(GetSchoolFailure(response.data))
    }

  } catch (err) {
    yield put(GetSchoolFailure(err.data))
  }

}

function* AddSchoolRequest(action) {
  console.log('AddSchoolRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'student-school-crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddSchoolSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/employee/view/${action.payload.obj.employee_id}`)
    } else {
      yield put(AddSchoolFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddSchoolFailure(err.data))

  }


}

function* UpdateSchoolRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `student-school-crud/?server_type=production&method=edit&id=${action.payload.employee_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateSchoolSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(UpdateSchoolFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateSchoolFailure(err.data))
  }

}

function* DeleteSchoolRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `student-school-crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteSchoolSuccess(response.data))

    } else {
      yield put(DeleteSchoolFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteSchoolFailure(err.data))
  }

}

function* FetchStudentActivityRequest(action) {
  console.log("Qwertyuioiuytr FetchStudentActivityRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `activities_crud/?server_type=production&id=${action.payload.rowId}&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(FetchStudentActivitySuccess(response.data))
    } else {
      yield put(FetchStudentActivityFailure(response.data))
    }

  } catch (err) {
    yield put(FetchStudentActivityFailure(err.data))
  }

}

function* GetStudentActivityRequest(action) {
  console.log("qwertyugfcxzbxhgzshcgshg GetStudentActivityRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `activities_crud/?server_type=production&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(GetStudentActivitySuccess(response.data))
    } else {
      yield put(GetStudentActivityFailure(response.data))
    }

  } catch (err) {
    yield put(GetStudentActivityFailure(err.data))
  }

}

function* AddStudentActivityRequest(action) {
  console.log('AddStudentActivityRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'activities_crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddStudentActivitySuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.obj.student_id}`)
    } else {
      yield put(AddStudentActivityFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddStudentActivityFailure(err.data))

  }


}

function* UpdateStudentActivityRequest(action) {
  console.log("UpdateStudentActivityRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `student-activities_crud/?server_type=production&method=edit&id=${action.payload.rowId}&student_id=${action.payload.student_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateStudentActivitySuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(UpdateStudentActivityFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateStudentActivityFailure(err.data))
  }

}

function* DeleteStudentActivityRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApiWithParam, `student-activities_crud/?server_type=production&id=${action.payload}`, header);

    if (response.status === 200) {
      yield put(DeleteStudentActivitySuccess(response.data))

    } else {
      yield put(DeleteStudentActivityFailure(response.data))
    }

  } catch (err) {
    yield put(DeleteStudentActivityFailure(err.data))
  }

}

function* GetStudenttestRequest(action) {
  console.log("GetStudenttestRequest", action)

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `assigned-student-test-lists/?server_type=production&student_user_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetStudenttestSuccess(response.data))
    } else {
      yield put(GetStudenttestFailure(response.data))
    }

  } catch (err) {
    yield put(GetStudenttestFailure(err.data))
  }

}

function* AddStudentAssessmentRequest(action) {
  console.log('AddStudentAssessmentRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, 'activities_crud/?server_type=production', action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddStudentActivitySuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.obj.student_id}`)
    } else {
      yield put(AddStudentActivityFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }

  } catch (err) {
    console.log(err)
    yield put(AddStudentActivityFailure(err.data))

  }
}


function* GetStudentTestListRequest(action) {


  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-crud/?server_type=production&student_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetStudentTestListSuccess(response.data))
    } else {
      yield put(GetStudentTestListFailure(response.data))
    }

  } catch (err) {
    yield put(GetStudentTestListFailure(err.response))
  }

}


function* GetAssessmentAnswerRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `questions-list-with-answer/?server_type=production&test_id=${action.payload.id}`, header);

    if (response.status === 200) {
      yield put(GetAssessmentAnswerSuccess(response.data))
    } else {
      yield put(GetAssessmentAnswerFailure(response.data))
    }

  } catch (err) {
    yield put(GetAssessmentAnswerFailure(err.data))
  }

}

function* GetAssessmentResultRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-test-answer_details/?server_type=production&test_id=${action.payload.id}&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(GetAssessmentResultSuccess(response.data))
    } else {
      yield put(GetAssessmentResultFailure(response.data))
    }

  } catch (err) {
    yield put(GetAssessmentResultFailure(err.data))
  }

}

function* GetResultListRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `student-test-exam-questions-topics-list/?server_type=production&test_id=${action.payload.id}&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(GetResultListSuccess(response.data))
    } else {
      yield put(GetResultListFailure(response.data))
    }

  } catch (err) {
    yield put(GetResultListFailure(err.data))
  }

}

function* GetResultChartRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test/topic-chart-api/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(GetResultChartSuccess(response.data))
    } else {
      yield put(GetResultChartFailure(response.data))
    }

  } catch (err) {
    yield put(GetResultChartFailure(err.data))
  }

}

function* GetCompareChartRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test/exam-reslut-compare/?server_type=production&student_id=${action.payload.id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(GetCompareChartSuccess(response.data))
    } else if (response.status === 400) {
      yield put(GetCompareChartFailure(response.data))
    } else {
      yield put(GetCompareChartFailure(response.data))
    }

  } catch (err) {
    yield put(GetCompareChartFailure(err.data))
  }

}

function* UpdateAssessmentResultRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `exam-reslut-update/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}&method=edit`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(UpdateAssessmentResultSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/settings/Title_CP`)
    } else {
      yield put(UpdateAssessmentResultFailure(response.data))
    }

  } catch (err) {
    yield put(UpdateAssessmentResultFailure(err.data))
  }

}

function* GetLearningPlanRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test-learningpath-crud/?server_type=production&test_id=${action.payload.id}&student_id=${action.payload.student_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(GetLearningPlanSuccess(response.data))
      // Swal.fire({
      //   icon: "success",
      //   text: response?.data?.msg,
      // });
      // action.payload.navigate(`/${userinfo?.company_slug}/students/StudentLearningpath/${11}/${419}`)
    } else {
      yield put(GetLearningPlanFailure(response.data))
    }

  } catch (err) {
    yield put(GetLearningPlanFailure(err.data))
  }

}

function* ViewLearningPlanRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(getApi, `test-learningpath-crud/?server_type=production&test_id=${action.payload.id}&student_id=${action.payload.student_id}`, header);

    if (response.status === 200) {
      yield put(ViewLearningPlanSuccess(response.data))
      // Swal.fire({
      //   icon: "success",
      //   text: response?.data?.msg,
      // });
      action.payload.navigate(`/${userinfo?.company_slug}/students/StudentLearningpath/${11}/${419}`)
    } else {
      yield put(ViewLearningPlanFailure(response.data))
    }

  } catch (err) {
    yield put(ViewLearningPlanFailure(err.data))
  }

}

function* GetLearningPathMainRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test-learningpath-crud/?server_type=production&student_id=${action.payload.student_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(GetLearningPathMainSuccess(response.data))
      // Swal.fire({
      //   icon: "success",
      //   text: response?.data?.msg,
      // });
      action.payload.navigate(`/${userinfo?.company_slug}/students/StudentLearningpath/${action.payload.student_id}`)
    } else {
      yield put(GetLearningPathMainFailure(response.data))
    }

  } catch (err) {
    yield put(GetLearningPathMainFailure(err.data))
  }

}

function* GetLearningPlanListsRequest(action) {
  console.log('GetLearningPlanListsRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test-learningpath-details-crud/?server_type=production&test_id=${action.payload.id}&student_id=${action.payload.student_id}&learning_path_id=${action.payload.learning_path_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(GetLearningPlanListsSuccess(response.data))
      // action.payload.navigate(`/${userinfo?.company_slug}/students/learningplanmanager/${action.payload.testId}/${action.payload.studentId}`)
      // action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.studentId}`)

    } else {
      yield put(GetLearningPlanListsFailure(response.data))
    }

  } catch (err) {
    yield put(GetLearningPlanListsFailure(err.data))
  }

}

function* DownloadLearningPlanRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test-questions-topic-pdf-downlaod/?server_type=production&test_id=${action.payload.testId}&student_id=${action.payload.studentId}&topic_id=${action.payload.topicId}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(DownloadLearningPlanSuccess(response.data))
      const link = document.getElementById("DownloadQuestion");
      link.setAttribute("href", response.data?.pdf_download_link);
      link.click();
    } else {
      yield put(DownloadLearningPlanFailure(response.data))
    }

  } catch (err) {
    yield put(DownloadLearningPlanFailure(err))
  }

}

function* ShuffleLearningPathRequest(action) {
  console.log('ShuffleLearningPathRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(putApi, `test-learningpath-order/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(ShuffleLearningPathSuccess(response.data))
    } else {
      yield put(ShuffleLearningPathFailure(response.data))
    }

  } catch (err) {
    yield put(ShuffleLearningPathFailure(err.data))
  }

}


function* GetProgressCheckRequest(action) {
  console.log("qwertyugfcxzbxhgzshcgshg GetProgressCheckRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test-lp-check-progress-crud/?server_type=production&test_id=${action.payload.id}&student_id=${action.payload.student_id}&learning_path_id=${action.payload.learning_path_id}`, action.payload.obj, header);

    if (response.status === 200) {
      yield put(GetProgressCheckSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
    } else {
      yield put(GetProgressCheckFailure(response.data))
    }

  } catch (err) {
    yield put(GetProgressCheckFailure(err.data))
  }

}

function* AddProgressCheckRequest(action) {
  console.log('AddProgressCheckRequest', action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, `test-lp-check-progress-test-question-create/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}&learning_path_id=${action.payload.progress_check_id}`, action.payload.obj, header);

    console.log(response.data)

    if (response?.data?.status === 200) {
      yield put(AddProgressCheckSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.student_id}`)
    } else {
      yield put(AddProgressCheckFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddProgressCheckFailure(err.data))

  }

}

function* GetProgressReportRequest(action) {
  console.log("qwertyugfcxzbxhgzshcgshg GetStudentReportRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, `test-lp-check-progress-test-question-create/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}&progress_check_id=${action.payload.progress_check_id}`, header);

    if (response.status === 200) {
      yield put(GetProgressReportSuccess(response.data))
    } else {
      yield put(GetProgressReportFailure(response.data))
    }

  } catch (err) {
    yield put(GetProgressReportFailure(err.data))
  }

}




function* AddServicesRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'service-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddServicesSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/curriculum/grade`)
    } else {
      yield put(AddServicesFailure(response.data))
    }

  } catch (err) {
    yield put(AddServicesFailure(err.data))
  }

}

function* AddServiceSubjectRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'srvicesubject-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddServiceSubjectSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/curriculum/grade`)
    } else {
      yield put(AddServiceSubjectFailure(response.data))
    }

  } catch (err) {
    yield put(AddServiceSubjectFailure(err.data))
  }

}

function* AddStaffRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'staff-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddStaffSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/curriculum/grade`)
    } else {
      yield put(AddStaffFailure(response.data))
    }

  } catch (err) {
    yield put(AddStaffFailure(err.data))
  }

}

function* AddPricingRequest(action) {

  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {

    let response = yield call(postApi, 'pricing-crud/?server_type=production', action.payload.obj, header);

    if (response.status === 200) {
      yield put(AddPricingSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // action.payload.navigate(`/${userinfo?.company_slug}/curriculum/grade`)
    } else {
      yield put(AddPricingFailure(response.data))
    }

  } catch (err) {
    yield put(AddPricingFailure(err.data))
  }

}

function* AddStudentEnrollRequest(action) {
  console.log("AddStudentEnrollRequest", action)
  let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken: token
  };

  try {
    let response = yield call(postApi, `student-admission-crud/?server_type=production&student_id=${action.payload.student_id}`, action.payload.obj, header);

    console.log(userinfo?.company_slug)

    if (response?.data?.status === 200) {
      yield put(AddStudentEnrollSuccess(response.data))
      Swal.fire({
        icon: "success",
        text: response?.data?.msg,
      });
      // {userinfo?.company_slug}/students/PaymentLink/${item?.id}/${item?.company_id}/${id}`}>

      action.payload.navigate(`/${userinfo?.company_slug}/students/PaymentLink/${action.payload.obj.service_id}/${action.payload.obj.company_id}/${action.payload.student_id}`)
      // action.payload.navigate(`/${userinfo?.company_slug}/students/view-students/${action.payload.student_id}`)
    } else {
      yield put(AddStudentEnrollFailure(response.data))
      Swal.fire({
        icon: "error",
        text: response.data?.message,
      });
    }


  } catch (err) {
    console.log(err)
    yield put(AddStudentEnrollFailure(err.data))

  }


}



const watchFunction = [



  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetResultChartRequest', GetResultChartRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetCompareChartRequest', GetCompareChartRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddpunchRequest', AddpunchRequest)
  })(),


  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetAttendenceRequest', GetAttendenceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddExistingRequest', AddExistingRequest)
  })(),


  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetExistingTestRequest', GetExistingTestRequest)
  })(),


  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetExistingQuestionRequest', GetExistingQuestionRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteQuestionRequest', DeleteQuestionRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateQuestionRequest', UpdateQuestionRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewQuestionRequest', ViewQuestionRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DownloadQuestionRequest', DownloadQuestionRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchQuestionRequest', FetchQuestionRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateorderListRequest', UpdateorderListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetAssignEmployeelocationRequest', GetAssignEmployeelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetDefaultlocationsRequest', GetDefaultlocationsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/MakeLocationPrimaryRequest', MakeLocationPrimaryRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateEmployeelocationRequest', UpdateEmployeelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteEmployeelocationRequest', DeleteEmployeelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchEmployeelocationRequest', FetchEmployeelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddEmployeelocationRequest', AddEmployeelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetEmployeelocationRequest', GetEmployeelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/MakePrimaryRequest', MakePrimaryRequest)
  })(),


  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetCrudlocationRequest', GetCrudlocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetGuardianRequest', GetGuardianRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateTopicRequest', UpdateTopicRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteTopicRequest', DeleteTopicRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchTopicRequest', FetchTopicRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddTopicRequest', AddTopicRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTopicRequest', GetTopicRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteprospectsRequest', DeleteprospectsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteSyllabusRequest', DeleteSyllabusRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteModuleRequest', DeleteModuleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateModuleRequest', UpdateModuleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchModuleRequest', FetchModuleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateSyllabusRequest', UpdateSyllabusRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTypeRequest', GetTypeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddModuleRequest', AddModuleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetModuleRequest', GetModuleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchSyllabusRequest', FetchSyllabusRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddSyllabusRequest', AddSyllabusRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/LoginCompanyRequest', LoginCompanyRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetProspectiveRequest', GetProspectiveRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetCountryRequest', GetCountryRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetLocationRequest', GetLocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetInterestRequest', GetInterestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetStateRequest', GetStateRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetcityRequest', GetcityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddpropectsRequest', AddpropectsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewprospectsRequest', ViewprospectsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ExportProspectiveRequest', ExportProspectiveRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ExportEmployeeRequest', ExportEmployeeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdatepropectsRequest', UpdatepropectsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetEmployeeRequest', GetEmployeeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddemployeeRequest', AddemployeeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTitleRequest', GetTitleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchTitleRequest', FetchTitleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddTitleRequest', AddTitleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateTitleRequest', UpdateTitleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteTitleRequest', DeleteTitleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetBackgroundRequest', GetBackgroundRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetBackgroundListRequest', GetBackgroundListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchBccRequest', FetchBccRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddBackgroundRequest', AddBackgroundRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateBccRequest', UpdateBccRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteBccRequest', DeleteBccRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetManagerRequest', GetManagerRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTrainingRequest', GetTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTrainingListRequest', GetTrainingListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddTrainingRequest', AddTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchTrainingRequest', FetchTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateTrainingRequest', UpdateTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteTrainingRequest', DeleteTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetGroupRequest', GetGroupRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddGroupRequest', AddGroupRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchGroupRequest', FetchGroupRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateGroupRequest', UpdateGroupRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteGroupRequest', DeleteGroupRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetGradeRequest', GetGradeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddGradeRequest', AddGradeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchGradeRequest', FetchGradeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateGradeRequest', UpdateGradeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteGradeRequest', DeleteGradeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetSubjectRequest', GetSubjectRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetAllGroupListRequest', GetAllGroupListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddSubjectRequest', AddSubjectRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchSubjectRequest', FetchSubjectRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateSubjectRequest', UpdateSubjectRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteSubjectRequest', DeleteSubjectRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewEmployeeRequest', ViewEmployeeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateEmployeeRequest', UpdateEmployeeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteEmployeeRequest', DeleteEmployeeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetSyllabusRequest', GetSyllabusRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddguardianRequest', AddguardianRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewguardianRequest', ViewguardianRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ExportguardianRequest', ExportguardianRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateguardianRequest', UpdateguardianRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteguardianRequest', DeleteguardianRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddlocationRequest', AddlocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewlocationRequest', ViewlocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ExportlocationRequest', ExportlocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdatelocationRequest', UpdatelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeletelocationRequest', DeletelocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchlocationRequest', FetchlocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetlocationsRequest', GetlocationsRequest)
  })(),



  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddstudentRequest', AddstudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewstudentRequest', ViewstudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ExportstudentRequest', ExportstudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdatestudentRequest', UpdatestudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeletestudentRequest', DeletestudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchstudentRequest', FetchstudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetstudentRequest', GetstudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetModuleTypeRequest', GetModuleTypeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddModuleTypeRequest', AddModuleTypeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteModuleTypeRequest', DeleteModuleTypeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddtestRequest', AddtestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdatetestRequest', UpdatetestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GettestRequest', GettestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchtestRequest', FetchtestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeletetestRequest', DeletetestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddquesRequest', AddquesRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetquesRequest', GetquesRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddTeacherTrainingRequest', AddTeacherTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateTeacherTrainingRequest', UpdateTeacherTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTeacherTrainingRequest', GetTeacherTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteTeacherTrainingRequest', DeleteTeacherTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetSubjectChangeRequest', GetSubjectChangeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetProfileRequest', GetProfileRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateProfileRequest', UpdateProfileRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchTeacherTrainingRequest', FetchTeacherTrainingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTopicChangeRequest', GetTopicChangeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetModuleChangeRequest', GetModuleChangeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetSyllabusChangeRequest', GetSyllabusChangeRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetSyllabusTopicRequest', GetSyllabusTopicRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AssignScheduleRequest', AssignScheduleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AssignGroupScheduleRequest', AssignGroupScheduleRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetGradeStudentRequest', GetGradeStudentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTeacherActivityRequest', GetTeacherActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateTeacherActivityRequest', UpdateTeacherActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchTeacherActivityRequest', FetchTeacherActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteTeacherActivityRequest', DeleteTeacherActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddTeacherActivityRequest', AddTeacherActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddDocumentRequest', AddDocumentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchDocumentRequest', FetchDocumentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteDocumentRequest', DeleteDocumentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateDocumentRequest', UpdateDocumentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetDocumentRequest', GetDocumentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddattendanceRequest', AddattendanceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchattendanceRequest', FetchattendanceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteattendanceRequest', DeleteattendanceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateattendanceRequest', UpdateattendanceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetattendanceRequest', GetattendanceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateEmployeevirtuallocationRequest', UpdateEmployeevirtuallocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteEmployeevirtuallocationRequest', DeleteEmployeevirtuallocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchEmployeevirtuallocationRequest', FetchEmployeevirtuallocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddEmployeevirtuallocationRequest', AddEmployeevirtuallocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetEmployeevirtuallocationRequest', GetEmployeevirtuallocationRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GettestfilterRequest', GettestfilterRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetTeacherRequest', GetTeacherRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateTeacherRequest', UpdateTeacherRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchTeacherRequest', FetchTeacherRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteTeacherRequest', DeleteTeacherRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddTeacherRequest', AddTeacherRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetSchoolRequest', GetSchoolRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateSchoolRequest', UpdateSchoolRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchSchoolRequest', FetchSchoolRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteSchoolRequest', DeleteSchoolRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddSchoolRequest', AddSchoolRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetStudentActivityRequest', GetStudentActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateStudentActivityRequest', UpdateStudentActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/FetchStudentActivityRequest', FetchStudentActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DeleteStudentActivityRequest', DeleteStudentActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddStudentActivityRequest', AddStudentActivityRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetStudenttestRequest', GetStudenttestRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddStudentAssessmentRequest', AddStudentAssessmentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetStudentTestListRequest', GetStudentTestListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetAssessmentAnswerRequest', GetAssessmentAnswerRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetAssessmentResultRequest', GetAssessmentResultRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetResultListRequest', GetResultListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/UpdateAssessmentResultRequest', UpdateAssessmentResultRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetLearningPlanRequest', GetLearningPlanRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ViewLearningPlanRequest', ViewLearningPlanRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetLearningPathMainRequest', GetLearningPathMainRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetLearningPlanListsRequest', GetLearningPlanListsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/DownloadLearningPlanRequest', DownloadLearningPlanRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/ShuffleLearningPathRequest', ShuffleLearningPathRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetStudentListRequest', GetStudentListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetProgressCheckRequest', GetProgressCheckRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddProgressCheckRequest', AddProgressCheckRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/GetProgressReportRequest', GetProgressReportRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddStudentEnrollRequest', AddStudentEnrollRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddServicesRequest', AddServicesRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddPricingRequest', AddPricingRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddStaffRequest', AddStaffRequest)
  })(),

  (function* () {
    yield takeLatest('FetchCompanyDataReducer/AddServiceSubjectRequest', AddServiceSubjectRequest)
  })(),

]

export default watchFunction;