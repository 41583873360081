import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams  } from 'react-router-dom'
import { GetCrudlocationRequest, DeletelocationRequest, ViewlocationRequest, ExportlocationRequest, FetchlocationRequest, GetDefaultlocationsRequest, UpdatelocationRequest, GetCountryRequest, GetstateSuccess, GetcitySuccess, GetcityRequest, GetStateRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";
import ApiConnection from '../../utils/ApiConnection'


const LocationDetails = () => {


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetCrudlocationResponse?.results

    console.log(fetchacompanyReducer.FetchlocationResponse)

    const [status, setstatus] = useState("")
    const [location, setlocation] = useState("")
    const [rejected, setrejected] = useState(true)
    const [title, settitle] = useState("")
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")
    const [date, setdate] = useState(null)
    const [url, seturl] = useState("")
    const [addressone, setaddressone] = useState("")
    const [addresstwo, setaddresstwo] = useState("")
    const [country, setcountry] = useState("")
    const [stateList, setstateList] = useState([])
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [cityList, setcityList] = useState([])
    const [zipcode, setzipcode] = useState("")
    const [iserror, setiserror] = useState("");
    const [lid, setlid] = useState("")

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const {id} = useParams()

    // useEffect(()=>{
    //     let obj ={
    //         id:lid
    //     }
    //     dispatch(FetchlocationRequest(obj))
    // },[])

    useEffect(() => {
        dispatch(GetCrudlocationRequest())
    }, [])

    useEffect(() => {
        dispatch(GetCrudlocationRequest())
    }, [fetchacompanyReducer?.DeletelocationResponse])

    useEffect(() => {
        dispatch(GetDefaultlocationsRequest(userinfo.user_id))
    }, [fetchacompanyReducer?.AddlocationResponse])

    

    const ResetHandle = ()=>{
        setstatus("")
        setlocation("")
        let obj= {
          "page_size":10,
          'page':1,
          'status':'',
          'location':''
        }
    
        dispatch(ViewlocationRequest(obj))
      }

      const SearchHandle = ()=>{
        let obj= {
          "page_size":10,
          'page':1,
          'status':status,
          'location':location
        }
    
        dispatch(ViewlocationRequest(obj))
      }

    useEffect(()=>{
  
        var editdata = fetchacompanyReducer?.FetchlocationResponse?.results[0]
        setlocation(editdata?.location_name)
        settitle(editdata?.location_title)
        setfname(editdata?.first_name)
        setlname(editdata?.last_name)
        setphone(editdata?.phone)
        setemail(editdata?.email)
        setdate(new Date(editdata?.date == undefined? new Date(): new Date(editdata?.date)))
        setstatus(editdata?.status)
        seturl(editdata?.url)
        setaddressone(editdata?.street_address_1)
        setaddresstwo(editdata?.street_address_2)
        setstate(editdata?.state_id)
        setcity(editdata?.city_id)
        setcountry(editdata?.country_id)
        setzipcode(editdata?.zipcode)
        setrejected(editdata?.is_virtual_location)

      
      },[fetchacompanyReducer?.FetchlocationResponse])

      useEffect(() => {
        dispatch(GetCountryRequest())
        dispatch(GetstateSuccess())
        dispatch(GetcitySuccess())
    }, [])

      const MakePrimary = async (rid) => {
        let obj ={
            id:rid
        }
        dispatch(FetchlocationRequest(obj))

        Swal.fire({
          title: 'Are you sure make it Virtual Location?',
        //   text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, make Virtual Location!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            let obj = {
                // "employee_id": userinfo?.user_type == "COMPANY" ? "" : userinfo.user_id,
                // "location_name": location,
                // "location_title": '',
                // "first_name": '',
                // "last_name": '',
                // "email": email,
                // "phone": phone,
                // "date":moment(date).format("YYYY-MM-DD") ,
                // "status": status,
                // "url": url,
                "is_virtual_location": rejected == false ? 0 : 1,
                // "street_address_1": addressone,
                // "street_address_2": addresstwo,
                // "country_id": Number(country),
                // "state_id": Number(state),
                // "city_id": Number(city),
                // "zipcode": Number(zipcode),
              }
              const response = await ApiConnection.put(`locations-crud/?server_type=production&id=${rid}&method=edit&is_hybrid_checked=${1}`, obj)
            // dispatch(UpdatelocationRequest({ obj: obj, id: rid, navigate: navigate }))
    
            if (response?.status == 200) {
              Swal.fire(
                'set Virtual Location!',
                'Your file has been set.',
                'success'
              )
            }
    
          }
        })
      }

    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeletelocationRequest(id))

                if (fetchacompanyReducer?.DeletelocationResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }





    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Location Management</h3>
                </div>
                <div className='row form-style mb-4 align-items-center'>
          <div className='col-lg-3'>
              <div className='form-group'>
                  <label>LOCATION</label>
                  <input type='text' className='form-control' placeholder='Search..'
                  value={location}
                  onChange={(e)=>setlocation(e.target.value)}
                  />
              </div>
          </div>

     <div className='col-lg-2'>
              <div className='form-group'>
                  <label>STATUS</label>
                 <select className='form-control' value={status}
                  onChange={(e)=>setstatus(e.target.value)}>
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">In-Active</option>
                 </select>
              </div>
          </div>


          <div className='col-lg-4'>
              <div className='form-group'>
                  <label className='d-block'>&nbsp;</label>
                  <div className='d-flex'>
                  <button className='btn btn-primary mr-3' onClick={SearchHandle}>Search</button>
                  <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
              </div>
              </div>
          </div>
      </div>
                <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/location/add-location`}><i className="fa-solid fa-plus"></i> Add Location</NavLink>
                        </li>
                        <li>
                <button className='formBtn1' ><i className="fa-solid fa-file-excel"></i> Export </button>
            </li>
                    </ul>
                </div>

                {groupList && groupList.length > 0 ?
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Location Name</th>
                                    <th>Email</th>
                                    <th>phone</th>
                                    <th>Status</th>
                                    <th>URL</th>
                                    <th>Is Hybrid Enabled</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupList && groupList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.location_name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.phone}</td>                                    
                                            <td>{item?.status}</td>
                                            <td>{item?.url}</td>
                                            <td><input type="checkbox" onClick={() => MakePrimary(item?.id)} defaultChecked={item.is_virtual_location === 1} /></td>
                                            <td>
                                                <NavLink to={`/${userinfo?.company_slug}/location/view-location/${item?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                                                <NavLink to={`/${userinfo?.company_slug}/location/edit-location/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                <button onClick={() => DeleteHandle(item.id)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}





                            </tbody>

                        </table>
                    </div>
                    :
                    <h6>No data found</h6>
                }


            </div>

        </div>
    )
}

export default LocationDetails