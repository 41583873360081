import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddpunchRequest, GetAttendenceRequest, GetDefaultlocationsRequest, GetTeacherActivityRequest} from '../../redux/reducer/FetchCompanyDataReducer'
import Multiselect from 'multiselect-react-dropdown';
import { LocationAction } from '../../redux/reducer/LocationHandle';
import { IMAGE } from '../../utils/Theme';
import Modal from 'react-bootstrap/Modal';
import { sidebarAction } from '../../redux/reducer/SidebarSlice';
import Swal from "sweetalert2";
import moment from 'moment';

const CompanyTopbar = () => {
  const [location, setlocation] = useState("")
  const [SelectLcation, setSelectLcation] = useState([])
  const handleClose2 = () => setShow2(false);
  const [show2, setShow2] = useState(false);
  const [ispunch, seispunch] = useState(null)


  

  const dispatch = useDispatch()
  let navigate = useNavigate()
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
 var locationList = fetchacompanyReducer?.GetDefaultlocationsResponse?.results
 var RemainderList = fetchacompanyReducer?.GetTeacherActivityRequest?.results[0]
 console.log(fetchacompanyReducer?.GetTeacherActivityRequest)
 //let Dlocation = localStorage.getItem('defaultLocation');
 let Dlocation = JSON.parse(localStorage.getItem("defaultLocation"));
 const locationReducer = useSelector((state) => state.LocationSlice)
 const sidebarReducer = useSelector((state) => state.sidebarSlice)
 var Punchobj = fetchacompanyReducer?.GetAttendenceResponse?.results?.[0]

 var C_time = moment(new Date()).format('LT')
 var ValidTime = C_time?.split(' ')[0]

 console.log("ispunch", ispunch)


 useEffect(()=>{
  dispatch(GetDefaultlocationsRequest(userinfo.user_id))
},[])

useEffect(()=>{
  dispatch(GetTeacherActivityRequest())
  dispatch(GetAttendenceRequest(userinfo.user_id))
},[])

console.log("local storagee", locationList)


const LocationHandle = (e) =>{
  setlocation(e.target.value)
  localStorage.setItem('defaultLocation', e.target.value);
}

const handleShow2 = () => {
  setShow2(true)
  dispatch(GetTeacherActivityRequest())
};

// useEffect(()=>{
//   if(Dlocation){
//     setlocation(Dlocation)
//   } else {
//     setlocation("")
//   }
// },[])


useEffect(()=>{
  dispatch(GetAttendenceRequest(userinfo.user_id))

},[fetchacompanyReducer?.AddpunchResponse])




const SignOutHandle = ()=>{
  Swal.fire({
    title: 'Are you sure log out?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, sign out it!'
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.reload();
      sessionStorage.clear();
    }
  })
}

const PunchInHandle = (status) => {

  if(status == 1){
    let obj = {
      "login_time": ValidTime,
      "is_login": status
    }
    dispatch(AddpunchRequest({employee_id:userinfo.user_id, obj:obj}))
  } else {
    let obj = {
      "logout_time": ValidTime,
      "is_login": status
    }
    dispatch(AddpunchRequest({employee_id:userinfo.user_id, obj:obj}))
  }
 
  

}



  return (
    <div className="topbar-header">
    <div className="row align-items-center">
    <div className="col-lg-2">
      <div className="brand-logo">
          <img src={IMAGE.c_logo} />
          <h5>Company Name</h5>
      </div>
    </div>
        <div className="col-lg-1">
        <button
         onClick={() =>
          dispatch(sidebarAction(sidebarReducer)) 
        }
  >
    {sidebarReducer?.isSidebarshow ? <i className="fa-solid fa-xmark"></i> :  <i className="fa-solid fa-bars"></i>  }

</button>
        </div>
        <div className="col-lg-9">
          <div class="row align-items-center">
            <div class="col-lg-6">
            <div class="header-location">
            <Multiselect
            options={locationList} 
            selectedValues={Dlocation}
            onSelect={(event)=>{
              
              var _comValue = "";
              var _item = []
             {event.map((item, index)=>{
                var dataitem =  item.id;
                _comValue += dataitem + ',';
                setSelectLcation(_comValue)
                _item.push(item)
                localStorage.setItem('locationId', _comValue);
               
             })}
             localStorage.setItem('defaultLocation', JSON.stringify(_item));
             dispatch(LocationAction(_comValue))
          }} 
          onRemove={(event, selectedItem)=>{
            console.log(selectedItem)
            var _comValue = "";
            var _item = []
            {event.map((item)=>{
               var dataitem =  item.id;
               _comValue += dataitem + ',';
               setSelectLcation(_comValue)
               _item.push(item)
              
            })}
            localStorage.setItem('defaultLocation', JSON.stringify(_item));
            localStorage.setItem('locationId', _comValue);
            dispatch(LocationAction(_comValue))
          }}
            displayValue="location_name" 
            placeholder="select location"
            />

            {/* <select className='form-control'  value={location}
                onChange={(e)=>LocationHandle(e)}>
                  <option value="">--Location---</option>
                  {locationList&&locationList.map((item, i)=>{
                      return (
                          <option key={i} value={item?.id}>{item?.location_name}</option>
                      )
                  })}
                </select> */}
            </div>
            </div>
            <div class="col-lg-6">
            <ul className='header-right-m'>
              
            <li>
            {Punchobj?.is_login == 1 ? 
                  <button  onClick={()=>PunchInHandle(0)} type="button" className="btn btn-danger" style={{padding:"8px 15px",background:'#61738d',color:'#fff'}}>Punch Out</button>
            :
            <button  onClick={()=>PunchInHandle(1)} type="button" className="btn btn-primary" style={{padding:"8px 15px",background:'#61738d',color:'#fff'}}>Punch In</button>

                }
                  </li>
            <li onClick={() => handleShow2()}>
              <i class="fa-solid fa-bell"></i> 
                </li>
                <li>
                    Sign in as <span>{userinfo?.name?.split(" ")[0]}</span>
                </li>
                <li>
                    <button onClick={SignOutHandle}>Sign Out</button>
                </li>
            </ul>
            </div>
          </div>
         
           
          </div>
    </div>

    <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header >
          <Modal.Title>Remainder </Modal.Title>
          <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
            <div className='view-page'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <p>P&T Metting</p>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/students`}><i className="fa-solid fa-angle-left"></i> Back to Activities</NavLink>
                        </li>

                    </ul>
                </div> */}


          </div>
        </Modal.Body>
      </Modal>
</div>
  )
}

export default CompanyTopbar